import React, { useState } from 'react';
import { Router, Route, Switch } from 'react-router-dom';
import Landing from './Pages/Unauthorized/Landing';
import StaffLogin from './Pages/Unauthorized/Staff/Login';
import StaffDashboard from './Pages/Authorized/Staff/Dashboard';
import StaffRegister from './Pages/Authorized/Staff/Register';
import StaffBed from './Pages/Authorized/Staff/Bed';
import StaffDoctor from './Pages/Authorized/Staff/Doctor';
import StaffAmbulance from './Pages/Authorized/Staff/Ambulance';
import StaffSummary from './Pages/Authorized/Staff/Summary';
import CustomerLogin from './Pages/Unauthorized/Customer/Login';

import CustomerDashboard from './Pages/Authorized/Customer/Dashboard';
import CustomerDashboardDetail from './Pages/Authorized/Customer/Dashboard/indexDetail';

import CustomerCancelCase from './Pages/Authorized/Customer/CancelCase'
import history from './history'
import CustomerProtectRoute from './Components/ProtectedRoute/customer'
import ProtectedRoute from './Components/ProtectedRoute'
import { allowedUsage } from './Utilities';

function App() {
  const [user, setUser] = useState(allowedUsage(sessionStorage.getItem('staffSessionExpired')))
  const handleLogin = () => setUser(true)
  const handleLogout = () => setUser(false)
  return (
    <div className="wrapper">
      <Router history={history}>
        <Switch>
          <Route exact path='/' component={Landing} />
          <CustomerProtectRoute path='/login' component={CustomerLogin} />
          <CustomerProtectRoute path='/dashboard' component={CustomerDashboard} />
          <CustomerProtectRoute path='/cancelcase' component={CustomerCancelCase} />
          <Route path="/dashboardDetail/:id" component={CustomerDashboardDetail} />

          <ProtectedRoute exact path='/staff/login' component={StaffLogin} user={user} handleLogin={handleLogin} />
          <ProtectedRoute exact path='/staff/dashboard' component={StaffDashboard} user={user} handleLogout={handleLogout} />
          <ProtectedRoute exact path='/staff/register' component={StaffRegister} user={user} handleLogout={handleLogout} />
          <ProtectedRoute exact path='/staff/bed' component={StaffBed} user={user} handleLogout={handleLogout} />
          <ProtectedRoute exact path='/staff/doctor' component={StaffDoctor} user={user} handleLogout={handleLogout} />
          <ProtectedRoute exact path='/staff/ambulance' component={StaffAmbulance} user={user} handleLogout={handleLogout} />
          <ProtectedRoute exact path='/staff/summary' component={StaffSummary} user={user} handleLogout={handleLogout} />
          <Route path="*" component={() => "404 NOT FOUND"} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;