import React from 'react'
import { Route, Redirect } from 'react-router-dom'

const CustomerRoute = ({ component: Component, ...rest }) => {
  let isActive = sessionStorage.getItem("customerActive") ? sessionStorage.getItem("customerActive") : false
  return (
    <Route {...rest} render={
      (props) => {
        if (isActive) {
          if (rest.path === "/login") return <Redirect to={{
            pathname: "/dashboard", state: {
              from: props.location
            }
          }} />
          return <Component {...rest} {...props} />
        } else {
          if (rest.path === "/login") return <Component {...rest} {...props} />
          return <Redirect to={
            {
              pathname: "/login",
              state: {
                from: props.location
              }
            }
          }
          />
        }
      }
    }
    />
  )
}
export default CustomerRoute

