import React from 'react'
import OtpLoginForm from '../../../../Components/Forms/OtpLogin'
import Content from '../../../../Components/Pages/TopNav/Content'
import RelativesFooter from '../../../../Components/Footer/RelativesFooter'

export default function Login() {
  return (
    <React.Fragment>
      <Content isCustomer={true}>
        <div className='main-screen'>
          <OtpLoginForm />
          <div className="content">
            <div className="container">
              <div className="row">
                <div className="col d-flex justify-content-center">
                  <RelativesFooter />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Content>
    </React.Fragment>
  )
}