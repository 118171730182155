import React, { useState, useEffect, useContext, useRef } from 'react'
import Axios from 'axios'
import DateFnsUtils from '@date-io/date-fns';
import Select from 'react-select'
import Swal from 'sweetalert2'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { th } from 'date-fns/locale'
import { CustomInput, FormGroup, Label, Input, Row, Col } from 'reactstrap'

import Button from '../../../../Components/Buttons/ButtonNormal'
import Content from '../../../../Components/Pages/TopNav/Content'
import Fieldset from '../../../../Components/FieldSet'
import history from '../../../../history'
import Menu from '../../../../Components/StepProcessBar'
import THLoad from '../../../../Images/loading.png'
import THError from '../../../../Images/error.png'
import { formCompared, importImageAll, summaryLabelError } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'
import Icon from '@material-ui/core/Icon'



export default function Summary(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Declare variables
  const { setting, apis, saveSetting, tokenExpire } = useContext(StoreContext)             // Global variables by useContext

  // URL API
  const api_summary_insert = apis.api_url + apis.api_port + '/api/final/'
  const api_cancel_reason = api_summary_insert + 'reason'
  const api_process_sum = apis.api_url + apis.api_port + `/api/dashboard/process/${setting.case_id}?destination=summary`

  // Axios config
  const config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Initialize State

  // Checkbox
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)

  // Datetime field
  const [arriveDate, setArriveDate] = useState(null)
  const [arriveTime, setArriveTime] = useState(null)

  // Cancel option
  const [cancelOption, setCancelOption] = useState([])
  const [selectedCancel, setSelectedCancel] = useState(null)
  const [cancelReason, setCancelReason] = useState(false)
  const [customReason, setCustomReason] = useState('')

  // Edit form state
  const [formMethod, setFormMethod] = useState('post')
  const [blockedPatient, setBlockedPatient] = useState(false)
  const [comparedData, setComparedData] = useState({})

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render menu
  const [processState, setProcessState] = useState(4)
  const imageArr = importImageAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  const imageList = ['register', 'bed', 'doctor', 'ambulance', 'summary'].map((menu, index) => {
    if (index > processState)
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState }
    else
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState, link: `./${menu}` }
  })

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Handle event

  // On page loaded, get data
  useEffect(() => {
    async function fetchData() {
      // Get cancel option
      let cancelOptionArr = await Axios.get(api_cancel_reason, config)
        .then(res => {
          if (res.status === 200) {
            return res.data.response.map(reason => {
              return { value: reason.id, label: reason.reason }
            })
          }
        }).catch(error => {
          console.log(error.response ?? error)
          if (error.response) {
            let status = error.response.status
            if (status === 401) tokenExpire(props.handleLogout)
          }
          return []
        })

      // On page loaded, get data
      await Axios.get(api_process_sum, config)
        .then(res => {
          // console.log(res.data.response)
          if (res.data.response.have_data) {
            setBlockedPatient(true)
            let resp = res.data.response.data

            setComparedData({ is_success: resp.is_success })
            if (resp.is_success) {
              setProcessState(resp.patient_info.status_id - 1)
              let infoFinal = resp.final_info
              setChecked1(true)
              setChecked2(false)

              let dateArrived = null
              if (infoFinal.actual_arrive_date) {
                let dateField = new Date(infoFinal.actual_arrive_date)
                if (!isNaN(dateField)) dateArrived = dateField
              }
              setArriveDate(dateArrived)

              let timeArrived = null
              if (infoFinal.actual_arrive_time) {
                let tzOffset = new Date().getTimezoneOffset() * 60000
                let actual_arrive_time = new Date(`2020-01-01T${infoFinal.actual_arrive_time.split('+')[0]}.000Z`)
                timeArrived = new Date(actual_arrive_time.getTime() + tzOffset)
              }
              setArriveTime(timeArrived)

              setComparedData(comparedData => ({
                ...comparedData,
                dateArrived: dateArrived,
                timeArrived: timeArrived
              }))
            } else {
              let cancelInfo = resp.cancel_info
              setChecked1(false)
              setChecked2(true)
              setSelectedCancel(cancelOptionArr.find(reason => reason.value === cancelInfo.reason_id))
              if (cancelInfo.reason_other) setCustomReason(cancelInfo.reason_other)

              setComparedData(comparedData => ({
                ...comparedData,
                cancel_reason: resp.cancel_info.reason_id,
                other_reason: resp.cancel_info.reason_other
              }))
            }
          } else {
            setComparedData({ is_success: null })
          }
        }).catch(error => {
          console.log(error.response ?? error)
          if (error.response) {
            let status = error.response.status
            if (status === 401) tokenExpire(props.handleLogout)
          }
        })
      setCancelOption(cancelOptionArr)
    }
    fetchData()
    saveSetting(setting => ({ ...setting, case_modified: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Select checkbox (success)
  const handleChecked = event => {
    if (event.target.id === 'success') {
      setChecked1(true)
      setChecked2(false)
      setSelectedCancel(null)
      setCancelReason(false)
      setCustomReason('')
    } else {
      setChecked1(false)
      setChecked2(true)
      setArriveDate(null)
      setArriveTime(null)
    }
  }

  // Select checkbox (unsuccess)
  const handleReasonChange = option => {
    setSelectedCancel(option)
    if (option && option.value === 1) {
      setCancelReason(true)
    } else {
      if (!option) setCancelReason(false)
      else setCancelReason(true)
      setCustomReason('')
    }
  }

  // Input date field
  const handleArriveDateChange = date => {
    setArriveDate(date)
  }

  // Input time field
  const handleArriveTimeChange = time => {
    setArriveTime(time)
  }

  // Change page
  const handleChangePage = () => {
    formCompared(isModified(false))
  }

  const isModified = (flag = true) => {
    let updated = false
    if (comparedData.is_success === null) {
      // new case
      updated = (checked2 === true || arriveDate !== null || arriveTime !== null)
    } else {
      let con = false
      if (comparedData.is_success) {
        con = comparedData.dateArrived !== arriveDate || comparedData.timeArrived !== arriveTime
      }
      updated = comparedData.is_success !== checked1 || con
    }

    if (flag) saveSetting(setting => ({ ...setting, case_modified: !updated }))
    return updated
  }

  // Clear form data
  const handleClearForm = () => {
    setArriveDate(null)
    setArriveTime(null)
    setCustomReason('')
    setSelectedCancel(null)
    setCancelReason(false)

    setChecked1(true)
    setChecked2(false)
  }

  // Form submit
  const finalForm = useRef(null)
  const handleFormSubmit = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Loading . . .?',
      text: 'กรุณารอสักครู่',
      imageUrl: THLoad,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })
    const form = finalForm.current
    let form_success = form['success'].checked
    if (!form_success) {
      if (!selectedCancel) {
        Swal.fire('เกิดข้อผิดพลาด', 'กรุณาระบุเหตุผลให้ครบถ้วน', 'error')
        return false
      }
    }
    const data = form_success ? {
      'is_success': true,
      'arrive_date': arriveDate ? arriveDate.toLocaleDateString('fr-CA') : '',
      'arrive_time': arriveTime !== null ? arriveTime.toTimeString().split(' ')[0] : ''
    } : {
        'is_success': false,
        'reason_id': selectedCancel.value,
        'other_reason': form['customReason'].value
      }
    Object.keys(data).forEach(key => (key !== 'is_success' && !data[key]) && delete data[key])

    if (formMethod === 'post') {
      Axios.post(api_summary_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'บันทึกสถานะผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            saveSetting(setting => ({ ...setting, case_id: 0, case_modified: false }))
            history.push('./dashboard')
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = summaryLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "บันทึกสถานะผู้ป่วยไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
        })
    } else if (formMethod === 'put') {
      Axios.put(api_summary_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'แก้ไขการสถานะผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            setBlockedPatient(true)
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = summaryLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "แก้ไขสถานะผู้ป่วยไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถแก้ไขข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
        })
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page

  return (
    <Content handleLogout={props.handleLogout}>
      <div className='main-screen'>
        {/* Title */}
        <label className='main-title'>สถานะเคส - ผู้ป่วยมาถึงโรงพยาบาลธนบุรี</label>

        {/* Menu */}
        <Menu images={imageList} />
        <div className='custom-spacing' />

        <form ref={finalForm} onSubmit={handleFormSubmit} onChange={isModified}>
          <Fieldset title='ผู้ผ่วยมาถึง โรงพยาบาลธนบุรี'>
            <CustomInput type="checkbox" id="success" label="สำเร็จ" onChange={handleChecked} checked={checked1} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
            <div className='patient-name-field' style={{ marginBottom: 50 }}>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={th}>
                <FormGroup>
                  <Label for='dateArrived'>วันที่ผู้ป่วยมาถึง โรงพยาบาลธนบุรี</Label>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='dateArrived'
                    name='dateArrived'
                    placeholder='วว/ดด/ปปปป'
                    value={arriveDate}
                    onChange={handleArriveDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputProps={{
                      style: {
                        fontFamily: `'Kanit', sans-serif`
                      }
                    }}
                    style={{ width: '100%', marginTop: 0 }}
                    disabled={blockedPatient || checked2}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='timeArrived'>เวลาที่ผู้ป่วยมาถึง โรงพยาบาลธนบุรี</Label>
                  <KeyboardTimePicker
                    margin='normal'
                    id='timeArrived'
                    name='timeArrived'
                    placeholder='ชช:นน'
                    ampm={false}
                    value={arriveTime}
                    onChange={handleArriveTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    keyboardIcon={<Icon>access_time</Icon>}
                    inputProps={{
                      style: {
                        fontFamily: `'Kanit', sans-serif`
                      }
                    }}
                    style={{ width: '100%', marginTop: 0 }}
                    disabled={blockedPatient || checked2}
                  />
                </FormGroup>
              </MuiPickersUtilsProvider>
            </div>

            <CustomInput type="checkbox" id="failed" label="ไม่สำเร็จ" onChange={handleChecked} checked={checked2} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
            <div className='patient-name-field'>
              <FormGroup>
                <Label>เหตุผล</Label>
                <Select
                  value={selectedCancel}
                  onChange={handleReasonChange}
                  options={cancelOption}
                  placeholder='เลือกเหตุผล'
                  isClearable={true}
                  isDisabled={blockedPatient || checked1}
                  id='selectUnsuccess'
                  name='selectUnsuccess'
                />
              </FormGroup>

              <FormGroup>
                <Label for='customReason'>อื่น ๆ</Label>
                <Input type='text' name='customReason' id='customReason' placeholder='กรุณากรอกเหตุผล' {...(cancelReason === true ? { required: 'required' } : { disabled: 'disabled' })} onChange={e => setCustomReason(e.target.value)} value={customReason} />
              </FormGroup>
            </div>
          </Fieldset>

          {blockedPatient ? (
            <Row style={{ margin: '20px 0' }}>
              <Col>
                <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
              </Col>
              <Col>
                <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='แก้ไข' align='left' onClick={e => { setBlockedPatient(false); setFormMethod('put') }} />
              </Col>
            </Row>
          ) : (
              <Row style={{ margin: '20px 0' }}>
                <Col>
                  <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
                </Col>
                <Col>
                  <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='ล้างฟอร์ม' onClick={handleClearForm} />
                </Col>
                <Col>
                  <Button type='submit' styles='primary' width='150' height='75' icon='Save' unit='px' text='บันทึก' align='left' />
                </Col>
              </Row>
            )}
        </form>
      </div>
    </Content>
  )
}
