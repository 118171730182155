import React, { useRef, useState, useContext, useEffect } from 'react'
import Swal from 'sweetalert2'
import Axios from 'axios'
import GmailTextbox from '../../Textboxes/Gmail'
import Button from '../../Buttons/ButtonNormal'
import Loading from '../../Loading'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import { StoreContext } from '../../../Context/Store'
import THError from '../../../Images/error.png'


export default function NormalLogin(props) {
  const { setting, apis } = useContext(StoreContext)
  const loginForm = useRef(null)
  const api_authen = apis.api_url + apis.api_port + '/api/member/authen'

  const [submitted, setSubmitted] = useState(null)
  const [tokenState, setTokenState] = useState(null)
  const [recaptchaState, setRecaptchaState] = useState(null)
  const { handleLogin } = props

  useEffect(() => {
    setTokenState(null)
    loadReCaptcha(setting.recaptchaSiteKey)
  }, [setting.recaptchaSiteKey])

  useEffect(() => {
    if (recaptchaState === true && tokenState !== null) {
      setSubmitted('show')
      const form = loginForm.current
      form['username'].blur()
      form['password'].blur()
      const data = {
        username: form['username'].value,
        password: form['password'].value,
        token_recaptcha: tokenState
      }
      // console.log(tokenState)
      Axios.post(api_authen, data)
        .then(res => {
          sessionStorage.setItem('staffJsonWebToken', res.data.response.token.access_token)
          sessionStorage.setItem('staffSessionExpired', res.data.response.payload.expire_time)
          if (res.data.response.payload.name)
            sessionStorage.setItem('staffName', [res.data.response.payload.name, res.data.response.payload.surname].join(' '))
          setSubmitted(null)
          setRecaptchaState(false)
          handleLogin()
        }).catch(error => {
          setSubmitted(null)
          setRecaptchaState(false)
          setTokenState(null)
          console.log(error.response ?? error)
          Swal.fire({
            title: error.response ? error.response.data.message : 'เกิดข้อผิดพลาด!',
            text: error.response ? error.response.data.reason : 'กรุณาติดต่อ IT หรือตรวจสอบที่คอนโซล',
            imageUrl: THError
          })
        })
    }
  }, [recaptchaState, tokenState, api_authen, handleLogin])

  const verifyCallback = (recaptchaToken) => {
    // console.log(tokenState)
    setTokenState(recaptchaToken)
  }

  const handleFormSubmit = event => {
    event.preventDefault()
    setRecaptchaState(true)
  }

  return (
    <div>
      {/* Content Header (Page header) */}
      <div className="content-header">
        <div className="container">
          <div className="row mb-2">

          </div>{/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      {/* /.content-header */}
      {/* Main content */}
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">Sign in</h3>
                </div>
                {/* /.card-header */}
                {/* form start */}
                <form ref={loginForm} onSubmit={handleFormSubmit}>
                  <div className="card-body">
                    <GmailTextbox name="username" type="text" placeholder="Enter your username" required="required" />
                    <GmailTextbox name="password" type="password" placeholder="Enter your password" required="required" />
                  </div>
                  {/* /.card-body */}
                  <div className="card-footer d-flex flex-row-reverse">
                    <Button type='submit' styles='primary' text='Login' loading={submitted} />
                  </div>
                </form>

                <Loading display={submitted} />
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
          {/* /.row */}
        </div>{/* /.container-fluid */}
      </div>
      {/* /.content */}
      {recaptchaState === true ? <ReCaptcha
        sitekey="6Lf3VAAVAAAAAPmVKVrsxRXzxbuh9ZL94LrsS2uv"
        action='submit'
        verifyCallback={verifyCallback}
      /> : null}
    </div>
  )
}