import React, { useContext } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { formCompared } from '../../../Utilities'
import { StoreContext } from '../../../Context/Store'

const StyleLI = styled.li`
        list-style-type: none;
        float: left;
        width: 20%;
        position: relative;
        text-align: center;
    &::before{
        background-color: white;
    }
    &::after{
        content: '';
        position: absolute;
        width: 100%;
        height: 3px;
        background-color: rgba(235, 231, 231);
        top: 50%;
        left: -50%;
    }
    &.li-start:after{
      content: none;
    }
    &.active:after {
      background-color: grey;
    }
    & img{
        background-color:  rgba(255, 255, 255);
        border: 3px solid rgba(235, 231, 231);
        border-radius: 50%;
        padding: 4px;
        position: relative;
        z-index: 1;
        cursor : not-allowed;
        opacity: .4;
        width: 80%;
        max-width: 78px;
    }   
    & img.active {
        border: 3px solid grey;
        opacity: 1;
        cursor: pointer;
    }
    
    &.active + &::after{
        background-color: grey;
    }
`

const Round = (props) => {
  const { setting } = useContext(StoreContext)             // Global variables by useContext

  const hasLink = setting.case_modified && !props.force ?
    (
      <StyleLI className={(props.active ? "active" : "") + (props.index === 1 ? ' li-start' : '')}>
        <img className={props.active ? "active" : ""} process={props.process} src={props.src} alt={props.alt} srcSet={props.srcSet} width={props.width} height={props.height} onClick={() => formCompared(true, props.link)} /><br />
      </StyleLI>
    ) : props.link ? (
      <StyleLI className={(props.active ? "active" : "") + (props.index === 1 ? ' li-start' : '')}>
        <Link to={props.link}>
          <img className={props.active ? "active" : ""} process={props.process} src={props.src} alt={props.alt} srcSet={props.srcSet} width={props.width} height={props.height} onClick={props.onclick} /><br />
        </Link>
      </StyleLI>
    ) : (
        <StyleLI className={(props.active ? "active" : "") + (props.index === 1 ? ' li-start' : '') + (props.force ? ' forced-width' : '')}>
          <img className={props.active ? "active" : ""} process={props.process} src={props.src} alt={props.alt} srcSet={props.srcSet} width={props.width} height={props.height} onClick={props.onclick} /><br />
        </StyleLI>
      )

  return (
    <div style={{ position: 'relative' }}>
      {hasLink}
    </div>
  )
}
export default Round;