import React, { useRef, useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons'
import './index.css'

export default function Gmail(props) {
  const [value, setValue] = useState(props.value ?? "");
  const [type, setType] = useState(props.type);
  const wrapper = useRef(null)
  const textInput = useRef(null)

  useEffect(() => {
    setValue(props.value)
  }, [props.value])

  const handleEyeClick = (e) => {
    textInput.current.focus()
    if (type === 'password') {
      setType('text')
    }
    else {
      setType('password')
    }
    setTimeout(() => {
      textInput.current.selectionStart = textInput.current.selectionEnd = textInput.current.value.length
    }, 10);
  }

  return (
    <div className={"input-group gmail-wrapper" + (value ? " hasValue" : "")} ref={wrapper}>
      {(value && props.type === 'password') ? <FontAwesomeIcon icon={type === 'password' ? faEye : faEyeSlash} onClick={handleEyeClick} /> : ""}
      <input type={type} name={props.name} onKeyUp={(e) => setValue(e.target.value)} ref={textInput} defaultValue={value} {...(props.required ? { required: 'required' } : {})} autoComplete='off' />
      <div className="gmail-placeholder" onClick={(e) => textInput.current.focus()}><span>{props.placeholder}</span></div>
    </div>
  )
}
