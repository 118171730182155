import React, { useContext } from 'react'
import { Route, Redirect } from 'react-router-dom'
import { StoreContext } from '../../Context/Store'

const ProtectedRoute = ({ component: Component, user, ...rest }) => {
  const { setting } = useContext(StoreContext)
  return (
    <Route {...rest} render={
      props => {
        if ((user && setting.case_id !== 0) || (user && rest.path.includes('/staff/dashboard')) || (!user && rest.path.includes('/staff/login'))) {
          return <Component {...rest} {...props} />
        } else {
          return <Redirect to={
            {
              pathname: user ? '/staff/dashboard' : '/staff/login',
              state: {
                from: props.location
              }
            }
          } />
        }
      }
    } />
  )
}

export default ProtectedRoute