import React, { useState, useContext, useRef, useEffect } from 'react'
import Axios from 'axios'
import DateFnsUtils from '@date-io/date-fns';
import Swal from 'sweetalert2'
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardTimePicker } from '@material-ui/pickers'
import { th } from 'date-fns/locale'
import { CustomInput, FormGroup, Label, Row, Col } from 'reactstrap'

import Button from '../../../../Components/Buttons/ButtonNormal'
import Content from '../../../../Components/Pages/TopNav/Content'
import Fieldset from '../../../../Components/FieldSet'
import history from '../../../../history'
import Menu from '../../../../Components/StepProcessBar'
import Modal from '../../../../Components/Modals/Cancellation'
import THLoad from '../../../../Images/loading.png'
import THError from '../../../../Images/error.png'
import { formCompared, importImageAll, ambulanceLabelError } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'
import Icon from '@material-ui/core/Icon'



export default function Ambulance(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Variables
  const { setting, apis, saveSetting, tokenExpire } = useContext(StoreContext)             // Global variables by useContext

  // URL API
  const api_ambulance_insert = apis.api_url + apis.api_port + '/api/ambulance/'
  const api_process_amb = apis.api_url + apis.api_port + `/api/dashboard/process/${setting.case_id}?destination=ambulance`
  const api_cancel_case = apis.api_url + apis.api_port + '/api/cancel/reason'

  // Axios config
  const config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Initialize State

  // Checkbox
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)

  // Datetime field
  const [pickupDate, setPickupDate] = useState(null)
  const [pickupTime, setPickupTime] = useState(null)
  const [arriveDate, setArriveDate] = useState(null)
  const [arriveTime, setArriveTime] = useState(null)

  // Edit form state
  const [formMethod, setFormMethod] = useState('post')
  const [blockedPatient, setBlockedPatient] = useState(false)
  const [comparedData, setComparedData] = useState({})
  const [pickupField, setPickupField] = useState(true)

  // Modal state
  const [useModal, setUseModal] = useState(false)
  const [modalOption, setModalOption] = useState([])
  const [extraCondition, setExtraCondition] = useState([])

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render menu
  const [processState, setProcessState] = useState(3)
  const imageArr = importImageAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  const imageList = ['register', 'bed', 'doctor', 'ambulance', 'summary'].map((menu, index) => {
    if (index > processState)
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState }
    else
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState, link: `./${menu}` }
  })

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Handle event

  // On page loaded, get data
  useEffect(() => {
    Axios.get(api_process_amb, config)
      .then(res => {
        if (res.data.response.have_data) {
          setBlockedPatient(true)
          let resp = res.data.response.data
          if (resp.patient_info.unsuccess_process_id)
            setProcessState(resp.patient_info.unsuccess_process_id - 2)
          else
            setProcessState(resp.patient_info.status_id - 1)

          let ambulanceTime = resp.ambulance_info
          setChecked1(ambulanceTime.pickup)
          setChecked2(!ambulanceTime.pickup)
          let tzOffset = new Date().getTimezoneOffset() * 60000
          let datePickup = ambulanceTime.plan_goarrive_date ? new Date(ambulanceTime.plan_goarrive_date) : null
          let timePickup = ambulanceTime.plan_goarrive_time ? new Date(`2020-01-01T${ambulanceTime.plan_goarrive_time.split('+')[0]}.000Z`) : null
          if (ambulanceTime.plan_goarrive_time) timePickup = new Date(timePickup.getTime() + tzOffset)
          let dateArrive = ambulanceTime.plan_arrive_date ? new Date(ambulanceTime.plan_arrive_date) : null
          let timeArrive = ambulanceTime.plan_arrive_time ? new Date(`2020-01-01T${ambulanceTime.plan_arrive_time.split('+')[0]}.000Z`) : null
          if (ambulanceTime.plan_arrive_time) timeArrive = new Date(timeArrive.getTime() + tzOffset)
          setPickupDate(datePickup)
          setPickupTime(timePickup)
          setArriveDate(dateArrive)
          setArriveTime(timeArrive)

          setComparedData({
            'datePickup': datePickup,
            'timePickup': timePickup,
            'dateArrive': dateArrive,
            'timeArrive': timeArrive
          })
          setPickupField(ambulanceTime.pickup)
        } else {
          setComparedData({ new_case: true })
        }
      }).catch(error => {
        console.log(error)
        let status = error.response.status
        if (status === 401) tokenExpire(props.handleLogout)
      })
    saveSetting(setting => ({ ...setting, case_modified: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Checkbox changed
  const handleChecked = event => {
    let ambulance = event.target.id === 'thPickup'
    setChecked1(ambulance)
    setChecked2(!ambulance)
    if (!ambulance) {
      setPickupDate(null)
      setPickupTime(null)
    }
  }

  // Change page
  const handleChangePage = () => {
    formCompared(isModified(false))
  }

  const isModified = (flag = true) => {
    if ('new_case' in comparedData) {
      // new case
      let result = pickupDate !== null || pickupTime !== null || arriveDate !== null || arriveTime !== null || checked2 === true
      if (flag) saveSetting(setting => ({ ...setting, case_modified: !result }))
      return (result)
    } else {
      let updated = (pickupDate !== comparedData.datePickup || pickupTime !== comparedData.timePickup || arriveDate !== comparedData.dateArrive || arriveTime !== comparedData.timeArrive)
      let pickup = pickupField !== checked1
      if (flag) saveSetting(setting => ({ ...setting, case_modified: !(updated || pickup) }))
      return (updated || pickup)
    }
  }


  // Input datetime field
  const handlePickupDateChange = date => {
    setPickupDate(date)
  }
  const handlePickupTimeChange = time => {
    setPickupTime(time)
  }
  const handleArriveDateChange = date => {
    setArriveDate(date)
  }
  const handleArriveTimeChange = time => {
    setArriveTime(time)
  }

  // Clear form data
  const handleClearForm = () => {
    setChecked1(true)
    setChecked2(false)

    setPickupDate(null)
    setPickupTime(null)
    setArriveDate(null)
    setArriveTime(null)
  }

  // Form submit
  const ambulanceForm = useRef(null)
  const handleFormSubmit = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Loading . . .?',
      text: 'กรุณารอสักครู่',
      imageUrl: THLoad,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })
    const form = ambulanceForm.current
    const data = {
      'is_pickup': form['thPickup'].checked,
      'goarrive_date': pickupDate ? pickupDate.toLocaleDateString('fr-CA') : '',
      'goarrive_time': pickupTime !== null ? pickupTime.toTimeString().split(' ')[0] : '',
      'arrive_date': arriveDate ? arriveDate.toLocaleDateString('fr-CA') : '',
      'arrive_time': arriveTime !== null ? arriveTime.toTimeString().split(' ')[0] : ''
    }
    Object.keys(data).forEach(key => (key !== 'is_pickup' && !data[key]) && delete data[key])

    if (formMethod === 'post') {
      Axios.post(api_ambulance_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'บันทึกการส่งตัวผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            history.push('./summary')
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = ambulanceLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "บันทึกการส่งตัวผู้ป่วยไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
        })
    } else if (formMethod === 'put') {
      Axios.put(api_ambulance_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'แก้ไขการส่งตัวผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            setBlockedPatient(true)
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถแก้ไขข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = ambulanceLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "แก้ไขการส่งตัวผู้ป่วยไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
        })
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Modal opened
  const openModal = () => {
    async function fetchReason() {
      let reasonOption = await Axios.get(api_cancel_case, config)
        .then(res => {
          return res.data.response.map(reason => {
            if (reason.detail) {
              let extraOption = reason.detail.map(detail => {
                return { value: detail.id, label: detail.name, description: detail.description }
              })
              setExtraCondition(extraOption)
            }
            return { value: reason.id, label: reason.reason }
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })
      setModalOption(reasonOption)
      setUseModal(true)
    }
    fetchReason()
  }

  // Modal closed
  const closeModal = () => {
    setUseModal(false)
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page

  return (
    <Content handleLogout={props.handleLogout}>
      <div className='main-screen'>
        {/* Title */}
        <label className='main-title'>สถานะเคส - การส่งตัวผู้ป่วย</label>

        {/* Menu */}
        <Menu images={imageList} />
        <div className='custom-spacing' />

        <form ref={ambulanceForm} onSubmit={handleFormSubmit} onChange={isModified}>
          <Fieldset title='การส่งตัวผู้ป่วย'>
            <div className='patient-name-field'>
              <Label>วิธีส่งตัวผู้ป่วย</Label>
              <CustomInput className='mb-2' type="checkbox" id="thPickup" name='thPickup' label="รถพยาบาล โรงพยาบาลธนบุรี ไปรับ" onChange={handleChecked} checked={checked1} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
              <CustomInput className='mb-2' type="checkbox" id="thWait" name='thWait' label="รถพยาบาล โรงพยาบาลต้นทาง นำส่ง" onChange={handleChecked} checked={checked2} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />

              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={th}>
                <FormGroup>
                  <Label for='datePickup'>วันที่คาดว่าจะไปถึง โรงพยาบาลต้นทาง (ระบุเป็นปี ค.ศ.)</Label>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='datePicker'
                    name='datePicker'
                    placeholder='วว/ดด/ปปปป'
                    value={pickupDate}
                    onChange={handlePickupDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputProps={{
                      style: {
                        fontFamily: `'Kanit', sans-serif`
                      }
                    }}
                    style={{ width: '100%', marginTop: 0 }}
                    disabled={blockedPatient || checked2}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='timePickup'>เวลาคาดว่าจะไปถึง โรงพยาบาลต้นทาง</Label>
                  <KeyboardTimePicker
                    margin='normal'
                    id='timePickup'
                    name='timePickup'
                    placeholder='ชช:นน'
                    ampm={false}
                    value={pickupTime}
                    onChange={handlePickupTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    keyboardIcon={<Icon>access_time</Icon>}
                    inputProps={{
                      style: {
                        fontFamily: `'Kanit', sans-serif`
                      }
                    }}
                    style={{ width: '100%', marginTop: 0 }}
                    disabled={blockedPatient || checked2}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='dateArrive'>วันที่คาดว่าจะมาถึง โรงพยาบาลธนบุรี (ระบุเป็นปี ค.ศ.)</Label>
                  <KeyboardDatePicker
                    disableToolbar
                    variant='inline'
                    format='dd/MM/yyyy'
                    margin='normal'
                    id='dateArrive'
                    name='dateArrive'
                    placeholder='วว/ดด/ปปปป'
                    value={arriveDate}
                    onChange={handleArriveDateChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change date',
                    }}
                    inputProps={{
                      style: {
                        fontFamily: `'Kanit', sans-serif`
                      }
                    }}
                    style={{ width: '100%', marginTop: 0 }}
                    disabled={blockedPatient}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for='timeArrive'>เวลาคาดว่าจะมาถึง โรงพยาบาลธนบุรี</Label>
                  <KeyboardTimePicker
                    margin='normal'
                    id='timeArrive'
                    name='timeArrive'
                    placeholder='ชช:นน'
                    ampm={false}
                    value={arriveTime}
                    onChange={handleArriveTimeChange}
                    KeyboardButtonProps={{
                      'aria-label': 'change time',
                    }}
                    keyboardIcon={<Icon>access_time</Icon>}
                    inputProps={{
                      style: {
                        fontFamily: `'Kanit', sans-serif`
                      }
                    }}
                    style={{ width: '100%', marginTop: 0 }}
                    disabled={blockedPatient}
                  />
                </FormGroup>
              </MuiPickersUtilsProvider>
            </div>
          </Fieldset>

          {blockedPatient ? (
            <Row style={{ margin: '20px 0' }}>
              <Col>
                <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
              </Col>
              <Col>
                <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='แก้ไข' align='left' onClick={e => { setBlockedPatient(false); setFormMethod('put') }} />
              </Col>
            </Row>
          ) : (
              <div>
                <Row style={{ margin: '20px 0' }}>
                  <Col>
                    <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
                  </Col>
                  <Col>
                    <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='ล้างฟอร์ม' align='left' onClick={handleClearForm} />
                  </Col>
                </Row>
                <Row style={{ margin: '20px 0' }}>
                  <Col>
                    <Button styles='danger' width='150' height='75' icon='TimesCircle' unit='px' text='ยกเลิกเคส' align='right' onClick={openModal} />
                  </Col>
                  <Col>
                    <Button type='submit' styles='primary' width='150' height='75' icon='Save' unit='px' text='บันทึก' align='left' />
                  </Col>
                </Row>
              </div>
            )}
        </form>

        <Modal show={useModal} closeModal={closeModal} option={modalOption} extra={extraCondition} />
      </div>
    </Content>
  )
}
