import React from 'react'
import styled from 'styled-components'

const StyleLegend = styled.legend`
    border-radius: 8px;
    font-size: 17px;
    font-weight: bold;
    padding: 3px 5px 3px 7px;
    margin: 12px;
    width: auto;
`
const StyleFieldSet = styled.fieldset`
    border: 1px solid grey;
    border-radius: 4px;
}
`

const FieldSet = (props) => {
  return (
    <StyleFieldSet className={props.class}>
      <StyleLegend>{props.title}</StyleLegend>
      {props.children}
    </StyleFieldSet>
  )
}

export default FieldSet;
