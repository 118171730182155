import React, { useEffect } from 'react'
import Header from './Header'
import './Content.css'

export default function Content(props) {

  useEffect(() => {
    document.body.classList.add('layout-top-nav')

    return () => {
      document.body.className = ""
    }
  }, []);

  return (
    <div style={{ textAlign: 'center' }}>
      <Header isCustomer={props.isCustomer} handleLogout={props.handleLogout} />
      <div className="content-wrapper">
        {
          props.children
        }
      </div>
    </div>
  )
}
