import React from 'react'
import NormalLoginForm from '../../../../Components/Forms/NormalLogin'
import Content from '../../../../Components/Pages/TopNav/Content'

export default function Login(props) {
  return (
    <Content>
      <div className='main-screen'>
        <NormalLoginForm {...props} />
      </div>
    </Content>
  )
}