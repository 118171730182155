import React, { useState, createContext } from 'react'
import Swal from 'sweetalert2'
import THError2 from '../../Images/error2.png'

export const StoreContext = createContext()

const defaultSetting = {
  case_id: 0,
  case_modified: false,
  ajaxTimeOut: 60000,
  intervalTimeOut: 20000,
  recaptchaSiteKey: "6Lf3VAAVAAAAAPmVKVrsxRXzxbuh9ZL94LrsS2uv"
}

const APIsSettings = {
    api_url: 'https://refer.thonburihospital.com',
    api_port: '',
  
    cus_url: 'https://refer.thonburihospital.com',
    cus_port: ''
}

export const StoreContextProvider = ({ children, setting, handleLogout }) => {
  const [currentSetting, setCurrentSetting] = useState(setting || defaultSetting)
  const [apis] = useState(APIsSettings)

  const saveSetting = value => {
    setCurrentSetting(value)
  }

  const tokenTimeoutHandler = (callback) => {
    let timerInterval;
    Swal.fire({
      imageUrl: THError2,
      title: 'หมดเวลาเข้าใช้งานระบบ!',
      html: 'จะออกภายใน <b></b> milliseconds.',
      timer: 10000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        sessionStorage.clear('staffJsonWebToken')
        sessionStorage.clear('staffSessionExpired')
        sessionStorage.clear('staffName')
        saveSetting({ ...defaultSetting, case_id: 0 })
        callback()

      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log('I was closed by the timer')
      }
    })
  }

  return (
    <StoreContext.Provider
      value={{ setting: currentSetting, apis: apis, saveSetting, tokenExpire: tokenTimeoutHandler }}
    >
      {children}
    </StoreContext.Provider>
  )
}

export default StoreContextProvider