import React from 'react'
import RoundImage from '../Menu/Round'

const StepProcess = (props) => {
  const images = props.images.map(image => {
    return <RoundImage src={image.src} process={image.process} alt={image.alt} srcSet={image.srcSet} key={image.id} index={image.id} width={image.width} height={image.height} active={image.active} link={image.link} onclick={props.onclick} />
  })
  return (
    <React.Fragment>
      <ul className="px-1">
        {images}
      </ul>
    </React.Fragment>
  )
}
export default StepProcess;
