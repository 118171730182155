import React, { useState, useContext } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Input } from 'reactstrap'
import THError from '../../../Images/error.png'
import Button from '../../Buttons/ButtonNormal'
import history from '../../../history'
import { StoreContext } from '../../../Context/Store'
import { cancelCaseLabelError } from '../../../Utilities'
import '../index.css'

export default function Cancellation(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Declare variables
  const { setting, apis, saveSetting, tokenExpire } = useContext(StoreContext)             // Global variables by useContext
  const { show, closeModal, option } = props

  // URL API
  const api_put_cancel = apis.api_url + apis.api_port + `/api/cancel/${setting.case_id}`

  // Axios config
  const config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Initialize State
  const [modalOption, setModalOption] = useState(null)
  const [reasonBlocked, setReasonBlocked] = useState(false)
  const [reasonText, setReasonText] = useState('')
  const [detailCondition, setDetailCondition] = useState(false)
  const [conditionOption, setConditionOption] = useState(null)

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Handle event

  // Change option
  const handleSelectChanged = option => {
    if (option && option.label === 'Condition') {
      setDetailCondition(true)
      setReasonBlocked(true)
      setReasonText('')
    } else {
      setDetailCondition(false)
      setReasonBlocked(true)
      setConditionOption(null)
      setReasonText('')
    }
    setModalOption(option)
  }

  const handleSelectCondition = option => {
    setConditionOption(option)
    setReasonText(option ? option.description : '')
  }

  // Modal closed
  const handleModalClosed = () => {
    if (modalOption) {
      Swal.fire({
        title: 'ระบบไม่ได้บันทึกการเปลี่ยนแปลง',
        text: 'คุณแน่ใจว่าต้องการออกจากการแก้ไข ใช่หรือไม่?',
        icon: 'warning',
        allowOutsideClick: false,
        showCancelButton: true,
        cancelButtonColor: '#c82333',
        confirmButtonText: 'ยืนยัน',
        cancelButtonText: 'ยกเลิก',
        reverseButtons: true
      }).then(result => {
        if (result.value === true) {
          closeModal()
          setModalOption(null)
          setConditionOption(null)
          setReasonBlocked(false)
          setDetailCondition(false)
          setReasonText('')
        }
      })
    } else {
      closeModal()
      setModalOption(null)
      setConditionOption(null)
      setReasonBlocked(false)
      setDetailCondition(false)
      setReasonText('')
    }
  }

  // Submit case
  const handleModalSubmit = () => {
    if (!modalOption || (modalOption.value === 999 && reasonText === '') || (modalOption.label === 'Condition' && !conditionOption)) {
      Swal.fire('เกิดข้อผิดพลาด!', 'กรุณาระบุเหตุผลการยกเลิกเคส Refer-In ให้ครบถ้วน', 'error')
    } else {
      let data = {}
      if (modalOption.label === 'Condition') {
        data = {
          cancel_id: modalOption.value,
          condition_id: conditionOption.value
        }
      } else {
        data = {
          cancel_id: modalOption.value,
          other_cancel: reasonText
        }
      }

      Axios.put(api_put_cancel, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'บันทึกยกเลิกเคส Refer-In เรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            saveSetting({ case_id: 0 })
            closeModal()
            history.push('./dashboard')
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = cancelCaseLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "บันทึกยกเลิกเคส Refer-Inไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
        })
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page

  return (
    <React.Fragment>
      <Modal isOpen={show} backdrop='static' keyboard={false} className='modal-custom modal-danger' size='lg'>
        <ModalHeader toggle={handleModalClosed}>ยกเลิกเคส Refer-In</ModalHeader>
        <ModalBody>
          <Row>
            <Col xs='4' style={{ textAlign: 'right' }}>เหตุผลที่ยกเลิกเคส : </Col>
            <Col xs='8'>
              <Select
                value={modalOption}
                onChange={handleSelectChanged}
                options={option}
                placeholder='กรุณาระบุเหตุผล'
              />
            </Col>
          </Row>

          {detailCondition ? (
            <React.Fragment>
              <br />
              <Row>
                <Col xs='4' style={{ textAlign: 'right' }}>เหตุผล : </Col>
                <Col xs='8'>
                  <Select
                    value={conditionOption}
                    onChange={handleSelectCondition}
                    options={props.extra}
                    placeholder='กรุณาระบุเหตุผล'
                  />
                </Col>
              </Row>
            </React.Fragment>
          ) : (<React.Fragment />)}

          {reasonBlocked ? (
            <React.Fragment>
              <br />
              <Row>
                <Col xs='4' style={{ textAlign: 'right' }}>{detailCondition ? 'รายละเอียด' : 'เหตุผล'} : </Col>
                <Col xs='8'>
                  <Input type='text' name='otherReason' id='otherReason' placeholder='กรุณาระบุเหตุผล' value={reasonText} onChange={e => setReasonText(e.target.value)} {...(detailCondition ? { disabled: 'disabled' } : {})} />
                </Col>
              </Row>
            </React.Fragment>
          ) : (<React.Fragment />)}

        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button styles='warning' width='125' unit='px' text='ปิด' align='right' onClick={handleModalClosed} />
            </Col>
            <Col>
              <Button styles='primary' width='125' unit='px' text='บันทึก' align='left' onClick={handleModalSubmit} />
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )
}
