import Swal from 'sweetalert2'
import history from '../history'

export const convertDate = (date, formatted = false) => {
  if (formatted && date) {
    let rawDt = date.split('Z')
    let rawDate = rawDt[0].split('T')[0] + 'T'
    let rawTime = rawDt[1].split('+')[0]
    date = rawDate + rawTime
  }

  if (!date) return '-'

  date = new Date(date)
  let entered = date.getTime()
  let minDate = new Date(70, 0).getTime()
  if (entered === minDate || isNaN(date.getTime())) return '-'

  let dd = padLeft(date.getDate().toString(), '0', 2)
  let MM = padLeft((date.getMonth() + 1).toString(), '0', 2)
  let yyyy = date.getFullYear()
  let HH = padLeft(date.getHours().toString(), '0', 2)
  let ii = padLeft(date.getMinutes().toString(), '0', 2)

  return {
    'datetime': [[dd, MM, yyyy].join('/'), [HH, ii].join(':')].join(' '),
    'date': [dd, MM, yyyy].join('/'),
    'time': [HH, ii].join(':')
  }
}

const padLeft = (result, char, length) => {
  if (!char || result.length >= length) return result

  let max = (length - result.length) / char.length
  for (let i = 0; i < max; i++)
    result = char + result
  return result
}

export const allowedUsage = expired => {
  if (!expired) return false
  let now = +new Date()
  expired = +new Date(expired)
  return now < expired
}

export const importImageAll = file => {
  return file.keys().map(file)
}

export const stringInsertAt = (str, insert, index) => {
  return str.slice(0, index) + insert + str.slice(index)
}

export const setConverseDate = dateStr => {
  let temp = dateStr.split('/')
  return [Number(temp[2]) - 543, temp[1], temp[0]].join('-')
}

export const formCompared = (updated, dest) => {
  if (updated) {
    Swal.fire({
      title: 'ระบบไม่ได้บันทึกการเปลี่ยนแปลง',
      text: 'คุณแน่ใจว่าต้องการออกจากการแก้ไข ใช่หรือไม่?',
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonColor: '#c82333',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    }).then(result => {
      if (result.value === true) {
        history.push(dest || './dashboard')
      }
    })
  } else {
    history.push(dest || './dashboard')
  }
}

const registerLabel = [
  { field: "hn", name: "HN" },
  { field: "prename", name: "คำนำหน้า" },
  { field: "name", name: "ชื่อ" },
  { field: "surname", name: "นามสกุล" },
  { field: "symtoms", name: "อาการสำคัญ" },
  { field: "sick_duration", name: "ระยะเวลาเจ็บป่วย" },
  { field: "treatment", name: "การรักษาในปัจจุบัน" },
  { field: "hospital", name: "รักษาอยู่ที่โรงพยาบาล" },
  { field: "hospital_phone", name: "เบอร์โทรศัพท์ (ติดตามสถานะผู้ป่วย)" },
  { field: "hospital_phone_reserve", name: "เบอร์โทรศัพท์" },
  { field: "relative_name", name: "ชื่อญาติ" },
  { field: "phone", name: "เบอร์โทรศัพท์" },
  { field: "phone_reserve", name: "เบอร์โทรศัพท์" },
]

export const registerLabelError = (field) => {
  const label = registerLabel.find(element => element.field === field)
  return label.name || "-"
}

const bedLabel = [
  { field: "is_success", name: "สำเร็จ" },
  { field: "division_id", name: "หน่วยงาน" },
  { field: "room_no", name: "เลขที่ห้อง" },
  { field: "reason_id", name: "เหตุผล" },
  { field: "other_reason", name: "อื่นๆ" },
]

export const bedLabelError = (field) => {
  const label = bedLabel.find(element => element.field === field)
  return label.name || "-"
}

const doctorLabel = [
  { field: "is_success", name: "สำเร็จ" },
  { field: "code", name: "รหัสแพทย์" },
  { field: "prename", name: "คำนำหน้า" },
  { field: "name", name: "ชื่อ" },
  { field: "surname", name: "นามสกุล" },
  { field: "reason_id", name: "เหตุผล" },
  { field: "other_reason", name: "อื่นๆ" },
]

export const doctorLabelError = (field) => {
  const label = doctorLabel.find(element => element.field === field)
  return label.name || "-"
}

const ambulanceLabel = [
  { field: "is_pickup", name: "วิธีส่งตัวผู้ป่วย" },
  { field: "goarrive_date", name: "วันที่คาดว่าจะไปถึง โรงพยาบาลต้นทาง" },
  { field: "goarrive_time", name: "เวลาคาดว่าจะไปถึง โรงพยาบาลต้นทาง" },
  { field: "arrive_date", name: "วันที่คาดว่าจะมาถึง โรงพยาบาลธนบุรี" },
  { field: "arrive_time", name: "เวลาคาดว่าจะมาถึง โรงพยาบาลธนบุรี" },
]

export const ambulanceLabelError = field => {
  const label = ambulanceLabel.find(element => element.field === field)
  return label.name || "-"
}

const summaryLabel = [
  { field: "is_success", name: "สำเร็จ" },
  { field: "arrive_date", name: "วันที่ผู้ป่วยมาถึง โรงพยาบาลธนบุรี" },
  { field: "arrive_time", name: "เวลาที่ผู้ป่วยมาถึง โรงพยาบาลธนบุรี" },
  { field: "reason_id", name: "เหตุผล" },
  { field: "other_reason", name: "อื่นๆ" },
]

export const summaryLabelError = field => {
  const label = summaryLabel.find(element => element.field === field)
  return label.name || "-"
}

const cancelCaseLabel = [
  { field: "cancel_id", name: "เหตุผลที่ยกเลิกเคส" },
  { field: "other_cancel", name: "อื่นๆ" }
]

export const cancelCaseLabelError = field => {
  const label = cancelCaseLabel.find(element => element.field === field)
  return label.name || "-"
}




