import React from 'react'
import Content from '../../../../Components/Pages/TopNav/Content'
import FieldSet from '../../../../Components/FieldSet'


const CancelCash = (props)=> {
    const patientName = "Mr.Mark Otto"
    return(
        <Content>
            <div className="main-screen">
                <div className="container">
                    <div className="row">
                        <div className="col">
                        <FieldSet title="ข้อมูลผู้รับบริการ">
                            <p className=" ml-5">ชื่อ-สกุล : {patientName}</p>
                        </FieldSet>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <FieldSet title="สถานะการดำเนินการ">
                            <div className="row">
                                <div className="col text-center">
                                    <p>โรงพยาบาลธนบุรียินดีให้บริการค่ะ</p>
                                    <p>ทาง รพ.ขออภัยเป็นอย่างยิงที่ไม่สามารถให้บริการท่านได้</p>
                                </div>
                            </div>
                        </FieldSet>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                        <br/><br/><br/>
                        ระบุรูปภาพ
                        </div>
                    </div>
                </div>
            </div>
        </Content>
    )
}

export default CancelCash;