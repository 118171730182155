import React from 'react'

const RelativesFooter = React.memo((props) => {
    // console.log("Render Footer")
    return (
        <div className="media" style={{paddingTop: "14px", paddingBottom: "20px"}}>
            <i className="fas fa-phone-square-alt fa-3x mr-3 align-self-center" style={{color: "black",opacity: 0.65}}></i>
            <div className="media-body align-self-center">
                <p className="text-left m-0" style={{fontSize: '0.8em'}}>
                    หากท่านมีข้อสอบถามเพิ่มเติมกรุณาติดต่อ <br />
                    โทร 0-2487-2000 ต่อ 2273, 2274 หรือ <br/>
                    063-217-1049 (พยาบาล Refer)
                </p>
            </div>
        </div>
    )
})
export default RelativesFooter
