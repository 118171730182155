import React, { useState, useRef, useContext, useEffect } from 'react'
import Swal from 'sweetalert2'
import GmailTextbox from '../../Textboxes/Gmail'
import Button from '../../Buttons/ButtonNormal'
import Axios from 'axios'
import history from '../../../history'
import { StoreContext } from '../../../Context/Store/'
import { loadReCaptcha, ReCaptcha } from 'react-recaptcha-v3'
import Loading from '../../Loading'
import THError from '../../../Images/error2.png'
import './index.css'

const msg_notfound_phoneno = "ขออภัยค่ะ ไม่พบหมายเลขโทรศัพท์นี้ในระบบ";
const msg_suggest_phoneno = "กรุณาตรวจสอบหมายเลขโทรศัพท์ของท่านใหม่อีกครั้งค่ะ.";
const msg_otp_notvalid = "ขออภัยค่ะ ท่านกรอกรหัส OTP ไม่ถูกต้อง";
const msg_suggest_otp_notvalid = "กรุณาลองใหม่อีกครั้งค่ะ.";
const msg_otp_expired = 'ขออภัยค่ะ รหัส OTP ของท่านหมดอายุแล้ว';
const msg_suggest_otp_expired = "กรุณาขอรหัส OTP ใหม่อีกครั้งค่ะ.";
const msg_server_error = "ขออภัยค่ะ ขณะนี้ระบบไม่สามารถให้บริการได้ชั่วคราว";
const msg_contact = "หากท่านมีข้อสอบถามสามารถติดต่อได้ที่ โทร 0-2487-2000 ต่อ 2273, 2274 (พยาบาล Refer)";

export default function OtpLogin(props) {
  const [sessionState, setSessionState] = useState(null)
  const [phoneState, setPhoneState] = useState(null)
  const [refCodeState, setRefCodeState] = useState(null)
  const [tokenState, setTokenState] = useState(null)
  const [loadingState, setLoadingState] = useState(null)
  const otpRequest = useRef(null)

  const { setting,apis } = useContext(StoreContext)
  const api = apis.cus_url + apis.cus_port

  const CancelToken = Axios.CancelToken;
  let cancel;

  useEffect(() => {
    setTokenState(null)
    loadReCaptcha(setting.recaptchaSiteKey)
    // 
    // const script = document.createElement('script')
    // script.src = `https://www.google.com/recaptcha/api.js?render=6Lf3VAAVAAAAAPmVKVrsxRXzxbuh9ZL94LrsS2uv`
    // script.async = true;
    // document.body.appendChild(script)
    // return () =>{
    //   document.body.removeChild(script)
    // }
  }, [setting.recaptchaSiteKey])

  useEffect(() => {
    if (sessionState === false && tokenState !== null) {
      setLoadingState("show")
      const form = otpRequest.current
      let phone = `${form['phone'].value.trim()}`
      let data = {
        relative_phone: phone,
        token_recaptcha: tokenState,
      }
      // console.log(tokenState)
      Axios.post(`${api}/api/relative/otp`, data,{
        headers : {
          datetime_data : Date.now()
        },
      })
        .then(res => {
          // console.log(res)
          let data = res.data.response
          // console.log(data)
          form['phone'].value = ""
          setLoadingState(null)
          setSessionState("true")
          setPhoneState(phone)
          setRefCodeState(data.ref_no)
        })
        .catch(err => {
          console.log(err.response)
          setLoadingState(null)
           let status = err.response.status
           let reason = err.response.data.reason
           let errorTitle = null
           let errorDetail = null
          if (status === 400) {
            if(reason.referral_error_code.trim() === "otppost_err_01")
            {
              errorTitle = msg_notfound_phoneno
              errorDetail = msg_suggest_phoneno
            }
            else if(reason.referral_error_code.trim() === "otppost_err_03"){
              // errorTitle = "ระบบยืนยันตัวตนมีปัญหา"
              errorTitle = msg_server_error
              errorDetail = msg_contact
            }
          }
          else if(status === 403){
            if(reason.referral_error_code.trim() === "otppost_err_03"){
              // errorTitle = "ระบบยืนยันตัวตนมีปัญหา"
              errorTitle = msg_server_error
              errorDetail = msg_contact
            }
          }
          else if (status === 500) {
            errorDetail = msg_contact
          }
          else if (status === 502) {
            errorTitle = msg_server_error
            errorDetail = msg_contact
          }
          else if (status === 503) {
            if(reason.referral_error_code.trim() === "otppost_err_02"){
              // errorTitle = "ไม่สามารถตอบกลับ otp ได้!"
              errorTitle = msg_server_error
              errorDetail =  msg_contact
            }
          }else{
            errorTitle = msg_server_error
            errorDetail =  msg_contact
          }
          Swal.fire({
            title: errorTitle,
            text: errorDetail,
            imageUrl : THError,
            confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
          })
          setSessionState(null)
          setTokenState(null)
        })
    }
  }, [sessionState, tokenState, api])

  const HandleOtpSubmit = e => {
    e.preventDefault()
    const form = otpRequest.current
    let phone = `${form['phone'].value.trim()}`
    if(phone ==="" || phone === null) return false
    setSessionState(false)
  }

  const handleFormSubmit = e => {
    e.preventDefault()
    const form = otpRequest.current
    let otp = `${form['otp'].value.trim()}`
    if(otp ==="" || otp === null) return false
    let refNo = refCodeState
    let data = {
      "ref_no": refNo,
      "otp": otp,
      datetime_data : Date.now()
    }
    if (cancel !== undefined) cancel()
    Axios.post(`${api}/api/relative/otp/otp_authen`, data, {
      headers: { datetime_data : Date.now() },
      cancelToken: new CancelToken(function executor(c) {
        // An executor function receives a cancel function as a parameter
        cancel = c;
      })
    })
      .then(res => {
        // console.log(res)
        let data = res.data.response
        // console.log(data)
        sessionStorage.setItem("customerActive", true)
        sessionStorage.setItem("customerPhone", phoneState)
        sessionStorage.setItem("customerToken", data.jwt_token)
        sessionStorage.setItem("referral_case", JSON.stringify(data.referral_case))
        sessionStorage.setItem("referral_case_list", JSON.stringify(data.referral_case_list))
        history.push({
          pathname: "/dashboard",
        })
      })
      .catch(err => {
        console.log(err.response)
        let status = err.response.status
        if (status === 400) {
          let reason = err.response.data.reason
          let errorTitle = ""
          let errorDetail = ""
          switch (reason.referral_error_code.trim()) {
            case 'otpauthenpost_err_01':
              errorTitle = msg_server_error // "ขออภัยค่ะ ท่านกรอกข้อมูลไม่ครบค่ะ."
              errorDetail = msg_contact // "กรุณาระบุ RefNo และ OTP ให้ครบถ้วนค่ะ"
              break;
            case 'otpauthenpost_err_02':
              errorTitle = `ขออภัยค่ะ ไม่พบเลข Ref NO. : ${refNo}`
              errorDetail = msg_suggest_otp_expired
              break;
            case 'otpauthenpost_err_03':
              errorTitle = msg_otp_notvalid
              errorDetail = msg_suggest_otp_notvalid
              break;
            case 'otpauthenpost_err_04':
              errorTitle = msg_otp_expired
              errorDetail = msg_suggest_otp_expired
              break;
            default:
              errorTitle = msg_server_error
              errorDetail = msg_contact
              break;
          }
          Swal.fire({
            title: errorTitle,
            text: errorDetail,
            imageUrl : THError,
            confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
          })
        }
        else if (status === 500) {
          Swal.fire({
            title: msg_server_error,
            text: msg_contact,
            imageUrl : THError,
            confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
          })
        }else{
          Swal.fire({
            title: msg_server_error,
            text: msg_contact,
            imageUrl : THError,
            confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
          })
        }
      })
  }

  const tryRequestOTPHandler = e => {
    setSessionState(null)
    setTokenState(null)
    let form = otpRequest.current
    form['phone'].value = phoneState
    form['phone'].focus()
  }

  const verifyCallback = (recaptchaToken) => {
    setTokenState(recaptchaToken)
  }

  let optLoginForm = null
  optLoginForm = (
    <form ref={otpRequest} onSubmit={sessionState === 'true' ? handleFormSubmit : HandleOtpSubmit}>
      <div className="card-body">
        {sessionState === "true" ? <div>หมายเลขโทรศัพท์ : <strong> {phoneState}</strong></div> : null}
        {sessionState === "true" ? <div>Ref Code : <strong>{refCodeState}</strong><br /><br /></div> : null}
        <GmailTextbox name={sessionState === 'true' ? 'otp' : 'phone'} type="text" placeholder={`กรุณากรอก ${sessionState === 'true' ? 'รหัส OTP' : 'หมายเลขโทรศัพท์'}`} required='required' />
      </div>
      <div className="card-footer clearfix">
        {sessionState === 'true' ? <Button onClick={tryRequestOTPHandler} type='button' text='ขอ OTP อีกครั้ง' styles='warning' width='49' /> : ''}
        {sessionState === 'true' ? <div style={{ width: '2%', display: 'inline-block' }} /> : ''}
        <Button type='submit' loading={loadingState} text={sessionState === 'true' ? 'เข้าสู่ระบบ' : 'ขอรหัส OTP'} styles={sessionState === 'true' ? 'primary' : 'warning'} width={sessionState === 'true' ? '49' : '100'} />
      </div>
    </form>
  )
  if (sessionState === true) {
    let form = otpRequest.current
    form['otp'].focus()
  }

  return (
    <React.Fragment>
      <div className="content-header">
        <div className="container">
          <div className="row mb-2">
          </div>
        </div>
      </div>
      <div className="content">
        <div className="container">
          <div className="row">
            <div className="col-lg-4"></div>
            <div className="col-lg-4">
              <div className="card card-primary">
                <div className="card-header">
                  <h3 className="card-title">เข้าสู่ระบบ</h3>
                </div>
                {optLoginForm}
                <Loading display={loadingState} />
              </div>
            </div>
            <div className="col-lg-4"></div>
          </div>
        </div>
      </div>
      {sessionState === false ? <ReCaptcha
        sitekey="6Lf3VAAVAAAAAPmVKVrsxRXzxbuh9ZL94LrsS2uv"
        action='submit'
        verifyCallback={verifyCallback}
      /> : null}
    </React.Fragment>
  )
}
