import React, { useState, useRef, useEffect, useContext } from 'react'
import { CustomInput, FormGroup, Label, Input, Row, Col } from 'reactstrap'
import AsyncSelect from 'react-select/async'
import { Link } from 'react-router-dom'
import Axios from 'axios'
import delayAdapterEnhancer from 'axios-delay'
import Swal from 'sweetalert2'
import MaskedInput from 'react-text-mask'
import Content from '../../../../Components/Pages/TopNav/Content'
import Menu from '../../../../Components/StepProcessBar'
import Fieldset from '../../../../Components/FieldSet'
import Button from '../../../../Components/Buttons/ButtonNormal'
import history from '../../../../history'
import { importImageAll, stringInsertAt, formCompared, registerLabelError } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import THLoad from '../../../../Images/loading.png'
import THError from '../../../../Images/error.png'
import AutoComplete from '@material-ui/lab/Autocomplete'
import './index.css'
import { TextField } from '@material-ui/core'

// Axios cancel request
const CancelToken = Axios.CancelToken
let cancel;

// Axios delay
const searchApi = Axios.create({
  adapter: delayAdapterEnhancer(Axios.defaults.adapter)
})

export default function Register(props) {
  const { setting, apis, saveSetting, tokenExpire } = useContext(StoreContext)

  // URL API
  const api_register = apis.api_url + apis.api_port + '/api/info/case'
  const api_patient = apis.api_url + apis.api_port + '/api/info/patient?search='
  const api_process_reg = apis.api_url + apis.api_port + `/api/dashboard/process/${setting.case_id}?destination=register`
  const api_delete_file = apis.api_url + apis.api_port + '/api/info/case/deletefile'
  const api_hospital = apis.api_url + apis.api_port + '/api/info/hospital'

  const config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  // onload get method
  const [blockedPatient, setBlockedPatient] = useState(false)
  const [patientPrename, setPatientPrename] = useState('')
  const [patientName, setPatientName] = useState('')
  const [patientSurname, setPatientSurname] = useState('')
  const [patientSymptom, setPatientSymptom] = useState('')
  const [durationSymptom, setDurationSymptom] = useState('')
  const [, setHospitalReferred] = useState(null)
  const [hospitalPhone, setHospitalPhone] = useState('')
  const [hospitalPhoneReserve, setHospitalPhoneReserve] = useState('')
  const [fileList, setFileList] = useState(null)
  const [relativeName, setRelativeName] = useState('')
  const [relativeTel, setRelativeTel] = useState('')
  const [relativeTel2, setRelativeTel2] = useState('')
  const [comparedData, setComparedData] = useState({})
  //AutoComplete Material UI
  const [inputValue, setInputValue] = React.useState("")
  const [openState, setOpenState] = React.useState(false)
  const [optionsState, setOptionsState] = useState([])
  const [initHospitalState, setInitHospitalState] = useState(null)
  const [, setSelectedFile] = useState(null)
  const [fileInputKey, setFileInputKey] = useState(Date.now())


  const handleOpen = () => {
    if (inputValue.length > 0) {
      setOpenState(true)
    }
  }

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue)
    if (newInputValue.length > 0) {
      setHospitalReferred(newInputValue)
      setOpenState(true)
    }
    else {
      setHospitalReferred(null)
      setOpenState(false)
    }
  }


  useEffect(() => {
    async function fetchData() {

      let optState = await Axios.get(api_hospital, config)
        .then(res => {
          // console.log(res.data.response)
          return res.data.response
        })
        .catch(err => {
          console.log(err)
          let status = err.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })

      setOptionsState(optState)

      await Axios.get(api_process_reg, config)
        .then(res => {
          if (res.data.response.have_data) {
            let patient = res.data.response.data
            if (patient.patient_info.unsuccess_process_id)
              setProcessState(patient.patient_info.unsuccess_process_id - 2)
            else
              setProcessState(patient.patient_info.status_id - 1)

            let customer = patient.patient_info
            let isNewPatient = customer.hn === null
            setChecked1(isNewPatient)
            setChecked2(!isNewPatient)
            setToggleNew(isNewPatient)
            setToggleOld(!isNewPatient)

            setBlockedPatient(true)
            if (isNewPatient) {
              setPatientName(customer.name)
              setPatientSurname(customer.surname)
              let prename = customer.prename
              if (prename === 'นาย') {
                setCheckedPre1(true)
                setCheckedPre2(false)
                setCheckedPre3(false)
                setCheckedPre4(false)
              } else if (prename === 'นาง') {
                setCheckedPre1(false)
                setCheckedPre2(true)
                setCheckedPre3(false)
                setCheckedPre4(false)
              } else if (prename === 'นางสาว') {
                setCheckedPre1(false)
                setCheckedPre2(false)
                setCheckedPre3(true)
                setCheckedPre4(false)
              } else {
                setCheckedPre1(false)
                setCheckedPre2(false)
                setCheckedPre3(false)
                setCheckedPre4(true)
                setPatientPrename(customer.prename)
              }
            } else {
              let hn = customer.hn + ''
              let option = { value: customer.hn, label: `[${stringInsertAt(hn, '-', hn.length - 2)}] ${customer.prename}${customer.name} ${customer.surname}`, prename: customer.prename, name: customer.name, surname: customer.surname }
              setSelectedOption(option)
            }

            let symptom = patient.symptom_info
            setPatientSymptom(symptom.symptoms)
            setDurationSymptom(symptom.duration ?? '')
            setHospitalReferred(symptom.hospital)
            if (symptom.hospital) {
              setInitHospitalState({
                "id": 0,
                hospital_name: symptom.hospital,
                hospital_phone: symptom.hospital_phone
              })
            }
            setHospitalPhone(symptom.hospital_phone)
            setHospitalPhoneReserve(symptom.hospital_phone_reserve)
            setOpenState(false)
            let admit = symptom.hospital !== null
            setChecked3(!admit)
            setChecked4(admit)
            // console.log(symptom)

            let files = symptom.files.map(file => {
              return <span key={file.file_name} className='badge bg-blue' style={{ fontWeight: 100, cursor: 'pointer', marginRight: 5 }}>{file.file_name.split('-')[1]}<FontAwesomeIcon icon={faTimes} size='sm' className='custom-deselect' onClick={() => handleDeleteFile(file.file_name)} /></span>
            })
            setFileList(<div style={{ marginBottom: 5 }}>{files}</div>)

            let relative = patient.relative_info
            setRelativeName(relative.name)
            setRelativeTel(relative.phone)
            setRelativeTel2(relative.phone_reserve)

            setComparedData({
              patientNew: isNewPatient,
              thPatient: customer.hn,
              prename: customer.prename,
              name: customer.name,
              surname: customer.surname,
              symptom: symptom.symptoms || '',
              duration: symptom.duration || '',
              treatment: symptom.hospital === null,
              hospital: symptom.hospital || '',
              hospitalPhone: symptom.hospital_phone || '',
              hospitalPhoneReserve: symptom.hospital_phone_reserve || '',
              relative: relative.name,
              relativePhone: relative.phone,
              relativePhone2: relative.phone_reserve || '',
            })
          } else {
            setComparedData({ new_case: true })
          }
        }).catch(error => {
          console.log(error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
        })
    }
    fetchData()
    saveSetting(setting => ({ ...setting, case_modified: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Get all images menu
  const [processState, setProcessState] = useState(0)
  const imageArr = importImageAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  // Get all menu
  let imageList = ['register', 'bed', 'doctor', 'ambulance', 'summary'].map((menu, index) => {
    if (index > processState)
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState }
    else
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState, link: `./${menu}` }
  })


  const handleClearForm = () => {
    registerForm.current.reset()
    setPatientName('')
    setPatientSurname('')
    setPatientSymptom('')
    setDurationSymptom('')
    setHospitalPhone('')
    setHospitalPhoneReserve('')
    setRelativeName('')
    setRelativeTel('')
    setRelativeTel2('')

    setCheckedPre1(true)
    setCheckedPre2(false)
    setCheckedPre3(false)
    setCheckedPre4(false)
    // console.log(selectedFile)
    setSelectedFile(null)
    setFileInputKey(Date.now())
    if (checked2) setSelectedOption(null)
    if (checked4) {
      const form = registerForm.current
      form["hospital"].value = ""
      setInitHospitalState("")
      setHospitalReferred("")
      setInputValue("")
    }
  }


  // Patient checkbox
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)
  const [toggleNew, setToggleNew] = useState(true)
  const [toggleOld, setToggleOld] = useState(false)
  const handleChecked = event => {
    let isNewPatient = event.target.id === 'patientNew'
    setChecked1(isNewPatient)
    setChecked2(!isNewPatient)
    setToggleNew(isNewPatient)
    setToggleOld(!isNewPatient)
  }

  const [checked3, setChecked3] = useState(true)
  const [checked4, setChecked4] = useState(false)
  const handleEntered = event => {
    // let treatment = event.target.id === 'fromHospital'
    let treatment = event.target.id
    // console.log(treatment)
    if (treatment === "fromHome") {
      setChecked3(true)
      setChecked4(false)

      setInitHospitalState("")
      setHospitalReferred("")
      setInputValue("")
      setHospitalPhoneReserve(null)
      setHospitalPhone(null)

      const form = registerForm.current
      form["hospital"].value = ""
      form["hospitalTelReserve"].value = ""
      form["hospitalTel"].value = ""
    } else if (treatment === "fromHospital") {
      setChecked3(false)
      setChecked4(true)
    }
  }

  const [checkedPre1, setCheckedPre1] = useState(true)
  const [checkedPre2, setCheckedPre2] = useState(false)
  const [checkedPre3, setCheckedPre3] = useState(false)
  const [checkedPre4, setCheckedPre4] = useState(false)
  const handlePrename = event => {
    let prename = event.target.id
    if (prename === 'prenameMr') {
      setCheckedPre1(true)
      setCheckedPre2(false)
      setCheckedPre3(false)
      setCheckedPre4(false)
      setPatientPrename('')
    } else if (prename === 'prenameMrs') {
      setCheckedPre1(false)
      setCheckedPre2(true)
      setCheckedPre3(false)
      setCheckedPre4(false)
      setPatientPrename('')
    } else if (prename === 'prenameMiss') {
      setCheckedPre1(false)
      setCheckedPre2(false)
      setCheckedPre3(true)
      setCheckedPre4(false)
      setPatientPrename('')
    } else if (prename === 'prenameOther') {
      setCheckedPre1(false)
      setCheckedPre2(false)
      setCheckedPre3(false)
      setCheckedPre4(true)
    }
  }

  // select searched patient
  const [selectedOption, setSelectedOption] = useState(null)
  const handleFilterChange = selectedOption => {
    // set option
    setSelectedOption(selectedOption)

    // check form modified
    if ('new_case' in comparedData) {
      saveSetting(setting => ({ ...setting, case_modified: selectedOption !== null }))
    } else {
      if (comparedData.thPatient) {
        if (selectedOption) {
          saveSetting(setting => ({ ...setting, case_modified: ((selectedOption.value + '') !== (comparedData.thPatient + '')) }))
        } else {
          saveSetting(setting => ({ ...setting, case_modified: true }))
        }
      } else {
        saveSetting(setting => ({ ...setting, case_modified: selectedOption !== null }))
      }
    }
  }

  // search patient
  const handleSearch = async (value) => {
    cancel && cancel()

    if (value.length > 2) {
      let extraConf = {
        cancelToken: new CancelToken(
          function executor(c) {
            cancel = c
          }
        ),
        delay: 250,
        ...config
      }

      return await searchApi.get(api_patient + value + '*', extraConf)
      .then(res => {
        return res.data.response.map(patient => {
          return { value: patient.hn, label: `[${patient.hn_number}-${patient.hn_year}] ${patient.prename_th}${patient.name_th} ${patient.surname_th}`, prename: patient.prename_th, name: patient.name_th, surname: patient.surname_th }
        })
      }).catch(error => {
        if (!searchApi.isCancel(error)) {
          if (error.response && error.response.status === 401) tokenExpire(props.handleLogout)
        }
        return null
      })
    }
  }

  // submit form
  const [formMethod, setFormMethod] = useState('post')
  const registerForm = useRef(null)
  const handleFormSubmit = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Loading . . .?',
      text: 'กรุณารอสักครู่',
      imageUrl: THLoad,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })
    const form = registerForm.current
    const data = new FormData()

    if (form['patientOld'].checked) {
      data.append('hn', selectedOption.value)
      data.append('prename', selectedOption.prename)
      data.append('name', selectedOption.name)
      data.append('surname', selectedOption.surname)
    } else {
      let prename = form['prenameOther'].checked ? form['prenameCustom'].value : form['prenameMr'].checked ? 'นาย' : form['prenameMrs'].checked ? 'นาง' : form['prenameMiss'].checked ? 'นางสาว' : ''
      data.append('prename', prename)
      data.append('name', form['patientName'].value)
      data.append('surname', form['patientSurname'].value)
    }

    if (form['symptom'].value) data.append('symtoms', form['symptom'].value)
    if (form['duration'].value) data.append('sick_duration', form['duration'].value)
    data.append('treatment', form['fromHospital'].checked)
    if (form['hospital'].value) data.append('hospital', form['hospital'].value)
    if (form['hospitalTel'].value) data.append('hospital_phone', form['hospitalTel'].value)
    if (form['hospitalTelReserve'].value) data.append('hospital_phone_reserve', form['hospitalTelReserve'].value)
    if (form['relativeName'].value) data.append('relative_name', form['relativeName'].value)
    if (form['relativeTel'].value) data.append('phone', form['relativeTel'].value)
    if (form['relativeTel2'].value) data.append('phone_reserve', form['relativeTel2'].value)
    for (let i = 0; i < form['fileBrowser'].files.length; i++)
      data.append('file', form['fileBrowser'].files[i])

    const config = {
      headers: {
        'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken'),
        'Content-Type': 'multipart/form-data'
      }
    }

    if (formMethod === 'post') {
      Axios.post(api_register, data, config)
        .then(res => {
          saveSetting(setting => ({ ...setting, case_id: res.data.response.referral_id, case_modified: false }))
          Swal.fire({
            title: 'Success',
            text: 'บันทึกการลงทะเบียนผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            history.push('./bed')
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = registerLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "บันทึกการลงทะเบียนไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
        })
    } else if (formMethod === 'put') {
      Axios.put(api_register + `/${setting.case_id}`, data, config)
        .then(res => {
          saveSetting(setting => ({ ...setting, case_id: res.data.response.referral_id, case_modified: false }))
          Swal.fire({
            title: 'Success',
            text: 'แก้ไขการลงทะเบียนผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            setBlockedPatient(true)
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = registerLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "แก้ไขการลงทะเบียนไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถแก้ไขข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
        })
    }
  }

  const handleChangePage = () => {
    formCompared(isModified(false))
  }

  const isModified = (flag = true) => {
    const form = registerForm.current
    let updated = false

    if (Object.keys(comparedData).length === 0) {
      // new case
      updated = form['patientOld'].checked ||
        !form['prenameMr'].checked ||
        form['patientName'].value !== '' ||
        form['patientSurname'].value !== '' ||
        form['symptom'].value !== '' ||
        form['duration'].value !== '' ||
        form['fromHospital'].checked ||
        form['fileBrowser'].files.length > 0 ||
        form['relativeName'].value !== '' ||
        form['relativeTel'].value !== '' ||
        form['relativeTel2'].value !== ''
    } else {
      // registered case

      let patientChanged = false
      if (form['patientNew'].checked) {
        let prenameCustomer = form['prenameOther'].checked ? form['prenameCustom'].value : form['prenameMr'].checked ? 'นาย' : form['prenameMrs'].checked ? 'นาง' : form['prenameMiss'].checked ? 'นางสาว' : ''
        patientChanged = comparedData.name !== form['patientName'].value ||
          comparedData.surname !== form['patientSurname'].value ||
          comparedData.prename !== prenameCustomer
      } else {
        patientChanged = selectedOption === null || comparedData.thPatient !== selectedOption.value
      }

      updated = comparedData.patientNew !== form['patientNew'].checked ||
        patientChanged ||
        comparedData.symptom !== form['symptom'].value ||
        parseInt(comparedData.duration) !== parseInt(form['duration'].value) ||
        comparedData.treatment !== form['fromHome'].checked ||
        comparedData.hospital !== form['hospital'].value ||
        comparedData.hospitalPhone !== form['hospitalTel'].value ||
        comparedData.hospitalPhoneReserve !== form['hospitalTelReserve'].value ||
        comparedData.relative !== form['relativeName'].value ||
        comparedData.relativePhone !== form['relativeTel'].value ||
        comparedData.relativePhone2 !== form['relativeTel2'].value
    }

    if (flag) saveSetting(setting => ({ ...setting, case_modified: updated }))
    return updated
  }

  // delete file
  const handleDeleteFile = (filename) => {
    Swal.fire({
      title: 'ลบไฟล์ออกจากระบบ?',
      text: `คุณต้องการลบไฟล์ ${filename.split('-')[1]} ออกจากระบบ Referral System ใช่หรือไม่?`,
      icon: 'warning',
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonColor: '#c82333',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    }).then(result => {
      if (result.value === true) {
        const config = {
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
          },
          data: {
            delete_files: filename
          }
        }

        Axios.delete(api_delete_file, config)
          .then(res => {
            console.log(res)
            Swal.fire('ลบไฟล์สำเร็จ!', `ลบไฟล์ ${filename.split('-')[1]} ออกจากระบบเรียบร้อย`, 'success').then(() => {
              let files = res.data.response.files.map(file => {
                return <span key={file.file_name} className='badge bg-blue' style={{ fontWeight: 100, cursor: 'pointer', marginRight: 5 }}>{file.file_name.split('-')[1]}<FontAwesomeIcon icon={faTimes} size='sm' className='custom-deselect' onClick={() => handleDeleteFile(file.file_name)} /></span>
              })
              setFileList(<div style={{ marginBottom: 5 }}>{files}</div>)
            })
          }).catch(error => {
            console.log(error.request)
            console.log(error.response ?? error)
            let status = error.response.status
            if (status === 401) tokenExpire(props.handleLogout)
            Swal.fire('เกิดข้อผิดพลาด!', `ไม่สามารถลบไฟล์ ${filename.split('-')[1]} ออกจากระบบได้`, 'error')
          })
      }
    })
  }

  return (
    <Content handleLogout={props.handleLogout}>
      <div className='main-screen'>
        {/* Title */}
        <label className='main-title'>สร้างเคสใหม่ - ข้อมูลเบื้องต้น</label>

        {/* Menu */}
        <Menu images={imageList} />
        <div className='custom-spacing' />
        <form ref={registerForm} id="registerForm" onSubmit={handleFormSubmit} onChange={isModified}>

          <Fieldset title='ข้อมูลผู้ป่วย'>
            <CustomInput type="checkbox" id="patientNew" label="ผู้ป่วยใหม่" onChange={handleChecked} checked={checked1} inline {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
            <CustomInput type="checkbox" id="patientOld" label="ผู้ป่วยเก่า" onChange={handleChecked} checked={checked2} inline {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />

            <div className='patient-name-field'>
              <div className={toggleNew ? '' : 'field-hidden'}>
                <FormGroup>
                  <Label for='patientPrename'>คำนำหน้าชื่อ</Label><br />
                  <CustomInput type="checkbox" id="prenameMr" label="นาย" onChange={handlePrename} checked={checkedPre1} inline {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
                  <CustomInput type="checkbox" id="prenameMrs" label="นาง" onChange={handlePrename} checked={checkedPre2} inline {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
                  <CustomInput type="checkbox" id="prenameMiss" label="นางสาว" onChange={handlePrename} checked={checkedPre3} inline {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
                  <CustomInput type="checkbox" id="prenameOther" label="อื่น ๆ (โปรดระบุ)" onChange={handlePrename} checked={checkedPre4} inline {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
                  <Input type='text' name='prenameCustom' id='prenameCustom' defaultValue={patientPrename} {...(!checkedPre4 || blockedPatient ? { disabled: 'disabled' } : { required: 'required' })} />
                </FormGroup>
                <FormGroup>
                  <Label for='patientName'>ชื่อผู้ป่วย</Label>
                  <Input type='text' name='patientName' id='patientName' autoComplete='off' defaultValue={patientName} {...(blockedPatient ? { disabled: 'disabled' } : {})} {...(toggleNew ? { required: 'required' } : {})} />
                </FormGroup>
                <FormGroup>
                  <Label for='patientSurname'>นามสกุล</Label>
                  <Input type='text' name='patientSurname' id='patientSurname' autoComplete='off' defaultValue={patientSurname} {...(blockedPatient ? { disabled: 'disabled' } : {})} {...(toggleNew ? { required: 'required' } : {})} />
                </FormGroup>
              </div>
              <div className={toggleOld ? '' : 'field-hidden'}>
                <FormGroup>
                  <Label>ค้นหาผู้ป่วย</Label>
                  <AsyncSelect
                    value={selectedOption}
                    onChange={handleFilterChange}
                    isClearable={true}
                    placeholder='ค้นหาผู้ป่วย'
                    loadOptions={handleSearch}
                    id='thPatient'
                    isDisabled={blockedPatient}
                  />
                </FormGroup>
              </div>
            </div>
          </Fieldset>

          <Fieldset title='ข้อมูลการเจ็บป่วย'>
            <FormGroup>
              <Label for='symptom'>การวินิจฉัยโรค</Label>
              <Input type='textarea' name='symptom' id='symptom' defaultValue={patientSymptom} {...(blockedPatient ? { disabled: 'disabled' } : {})} />
            </FormGroup>
            <FormGroup>
              <Label for='duration'>ระยะเวลาเจ็บป่วย (ระบุเป็นจำนวนวัน)</Label>
              <MaskedInput
                mask={[/\d/, /\d/, /\d/]}
                id='duration'
                name='duration'
                className='form-control'
                autoComplete='off'
                value={durationSymptom}
                onChange={e => setDurationSymptom(e.target.value)}
                placeholderChar={' '}
                {...(blockedPatient ? { disabled: 'disabled' } : {})}
              />
            </FormGroup>
            <FormGroup className={'reg-treatment'}>
              <Label>การรักษาในปัจจุบัน</Label>
              <CustomInput type="checkbox" id="fromHome" label="ยังไม่ได้รักษา" onChange={handleEntered} checked={checked3} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
              <CustomInput type="checkbox" id="fromHospital" label="รักษาอยู่ที่โรงพยาบาล" onChange={handleEntered} checked={checked4} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
              <div id='forFromHospital'>
                <AutoComplete
                  id="hospital"
                  freeSolo
                  clearText="เคลียร์ข้อมูล"
                  open={openState}
                  onOpen={handleOpen}
                  onClose={() => setOpenState(false)}
                  inputValue={inputValue}
                  onInputChange={handleInputChange}
                  options={optionsState}
                  getOptionLabel={option => option.hospital_name || ""}
                  style={{ marginLeft: "24px", width: "calc(100% - 24px)", marginBottom: "10px", backgroundColor: checked4 && !blockedPatient ? "white" : "#e9ecef" }}
                  value={initHospitalState}
                  onChange={(event, newValue) => {
                    // console.log(optionsState)
                    if (newValue !== null) {
                      setHospitalPhoneReserve(newValue.hospital_phone)
                    } else {
                      setHospitalPhoneReserve(null)
                    }
                  }}
                  disabled={checked4 && !blockedPatient ? false : true}
                  renderInput={param => (
                    <TextField {...param} id="hospital" name="hospital" label="" variant="outlined" size="small" required />
                  )}
                />
                {/* <Input type='text' name='hospital' id='hospital' autoComplete='off' defaultValue={hospitalReferred} {...(checked4 && !blockedPatient ? { required: 'required' } : { disabled: 'disabled' })} /> */}
                <Label for='hospitalTelReserve'>เบอร์โทรศัพท์</Label>
                <Input type='text' name='hospitalTelReserve' id='hospitalTelReserve' autoComplete='off' defaultValue={hospitalPhoneReserve} {...(checked4 && !blockedPatient ? {} : { disabled: 'disabled' })} />
                <Label for='hospitalTel'>เบอร์โทรศัพท์มือถือ (ใช้รับรหัส OTP สำหรับติดตามสถานะของเคส)</Label>
                <Input type='text' name="hospitalTel" id="hospitalTel" autoComplete="off" defaultValue={hospitalPhone} {...(checked4 && !blockedPatient ? {} : { disabled: 'disabled' })} />
              </div>
              <Label>เอกสารแนบ</Label>
              {fileList ?? ''}
              <CustomInput type="file" id="fileBrowser" key={fileInputKey} name="fileBrowser" label="กรุณาเลือกไฟล์" multiple {...(blockedPatient === true ? { disabled: 'disabled' } : {})} onChange={(e) => { setSelectedFile(e.target.files[0]) }} />
            </FormGroup>
          </Fieldset>

          <Fieldset title='ข้อมูลญาติที่ติดต่อได้'>
            <div className='patient-name-field'>
              <FormGroup>
                <Label for='relativeName'>ชื่อญาติ</Label>
                <Input type='text' name='relativeName' id='relativeName' autoComplete='off' defaultValue={relativeName} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} required />
              </FormGroup>
              <FormGroup>
                <Label for='relativeTel'>เบอร์โทรศัพท์มือถือเบอร์ที่ 1 (ใช้รับรหัส OTP สำหรับติดตามสถานะของเคส)</Label>
                <Input type='text' name='relativeTel' id='relativeTel' autoComplete='off' defaultValue={relativeTel} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} required />
              </FormGroup>
              <FormGroup>
                <Label for='relativeTel2'>เบอร์โทรศัพท์มือถือเบอร์ที่ 2 (ใช้รับรหัส OTP สำหรับติดตามสถานะของเคส)</Label>
                <Input type='text' name='relativeTel2' id='relativeTel2' autoComplete='off' defaultValue={relativeTel2} {...(blockedPatient === true ? { disabled: 'disabled' } : {})} />
              </FormGroup>
            </div>
          </Fieldset>

          {blockedPatient ? (
            <Row style={{ margin: '20px 0' }}>
              <Col>
                <Link to='./dashboard'>
                  <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' />
                </Link>
              </Col>
              <Col>
                <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='แก้ไข' align='left' onClick={e => { setBlockedPatient(false); setFormMethod('put') }} />
              </Col>
            </Row>
          ) : (
              <Row style={{ margin: '20px 0' }}>
                <Col>
                  <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
                </Col>
                <Col>
                  <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='ล้างฟอร์ม' onClick={handleClearForm} />
                </Col>
                <Col>
                  <Button type='submit' styles='primary' width='150' height='75' icon='Save' unit='px' text='บันทึก' align='left' />
                </Col>
              </Row>
            )}
        </form>
      </div>
    </Content>
  )
}


