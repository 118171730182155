import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHome, faEdit, faSave, faTimesCircle, faSignOutAlt } from '@fortawesome/free-solid-svg-icons'
import { Spinner } from 'reactstrap'

const faDict = {
  'faHome': faHome,
  'faEdit': faEdit,
  'faSave': faSave,
  'faTimesCircle': faTimesCircle,
  'faSignOutAlt': faSignOutAlt
}
export default function ButtonNormal(props) {
  return (
    <button
      type={`${props.type || 'button'}`}
      className={`btn btn-${props.styles || 'default'}`}
      style={{ width: `${props.width || '100'}${props.unit || '%'}`, float: `${props.align || 'none'}`, height: `${props.height + 'px' || 'auto'}` }}
      {...(props.onClick ? { onClick: props.onClick } : {})}
    >
      {props.icon ? <FontAwesomeIcon icon={faDict[`fa${props.icon}`]} size='lg' style={{ marginRight: 10 }} /> : ''}
      {props.loading === 'show' ? <React.Fragment><Spinner
        as='span'
        animation='grow'
        size='sm'
        role='status'
        aria-hidden='true'
        style={{ position: 'relative', marginRight: 10, top: -5 }}
      />Loading...</React.Fragment> : props.text || 'Button'}
    </button>
  )
}
