import React, { useState, useEffect, useContext, useRef } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import AsyncSelect from 'react-select/async'
import Swal from 'sweetalert2'
import { CustomInput, FormGroup, Label, Input, Row, Col } from 'reactstrap'

import Button from '../../../../Components/Buttons/ButtonNormal'
import Content from '../../../../Components/Pages/TopNav/Content'
import Fieldset from '../../../../Components/FieldSet'
import history from '../../../../history'
import Menu from '../../../../Components/StepProcessBar'
import Modal from '../../../../Components/Modals/Cancellation'
import THLoad from '../../../../Images/loading.png'
import THError from '../../../../Images/error.png'
import { formCompared, importImageAll, doctorLabelError } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'

// Axios cancel request
const CancelToken = Axios.CancelToken
let cancel;

export default function Doctor(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Variables
  const { setting, apis, saveSetting, tokenExpire } = useContext(StoreContext)             // Global variables by useContext

  // URL API
  const api_doctor_insert = apis.api_url + apis.api_port + '/api/doctor/'
  const api_cancel_reason = api_doctor_insert + 'reason'
  const api_doctor_search = api_doctor_insert + 'doctor?search='
  const api_process_doc = apis.api_url + apis.api_port + `/api/dashboard/process/${setting.case_id}?destination=doctor`
  const api_cancel_case = apis.api_url + apis.api_port + '/api/cancel/reason'

  // Axios config
  const config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Initialize State

  // Checkbox
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)

  // Doctor option
  const [selectedOption, setSelectedOption] = useState(null)
  const [specialistDr, setSpecialistDr] = useState('')
  const [clinic, setClinic] = useState('')

  // Cancel option
  const [cancelOption, setCancelOption] = useState([])
  const [selectedCancel, setSelectedCancel] = useState(null)
  const [cancelReason, setCancelReason] = useState(false)
  const [customReason, setCustomReason] = useState('')

  // Edit form state
  const [formMethod, setFormMethod] = useState('post')
  const [blockedPatient, setBlockedPatient] = useState(false)
  const [comparedData, setComparedData] = useState({})
  const [flagRadio, setFlagRadio] = useState(false)

  // Modal state
  const [useModal, setUseModal] = useState(false)
  const [modalOption, setModalOption] = useState([])
  const [extraCondition, setExtraCondition] = useState([])

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render menu
  const [processState, setProcessState] = useState(2)
  const imageArr = importImageAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  const imageList = ['register', 'bed', 'doctor', 'ambulance', 'summary'].map((menu, index) => {
    if (index > processState)
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState }
    else
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState, link: `./${menu}` }
  })

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Handle event

  // On page loaded, get data
  useEffect(() => {
    async function fetchData() {
      // Get cancel option
      let cancelOpt = await Axios.get(api_cancel_reason, config)
        .then(res => {
          if (res.status === 200) {
            return res.data.response.map(reason => {
              return { value: reason.id, label: reason.reason }
            })
          }
        }).catch(error => {
          console.log(error.response)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })

      // On page loaded, get data
      await Axios.get(api_process_doc, config)
        .then(res => {
          if (res.data.response.have_data) {
            setBlockedPatient(true)
            let resp = res.data.response.data

            setChecked1(resp.is_success)
            setChecked2(!resp.is_success)
            setComparedData({ is_success: resp.is_success })
            if (resp.is_success) {
              if (resp.patient_info.unsuccess_process_id)
                setProcessState(resp.patient_info.unsuccess_process_id - 2)
              else {
                setProcessState(resp.patient_info.status_id - 1)
                setFlagRadio(resp.patient_info.status_id > 4)
              }

              if (resp.doctor_info.doctor_code) {
                let option = { value: resp.doctor_info.doctor_code, label: `[${resp.doctor_info.doctor_code}] ${resp.doctor_info.doctor_prename}${resp.doctor_info.doctor_name} ${resp.doctor_info.doctor_surname}`, prename: resp.doctor_info.doctor_prename, name: resp.doctor_info.name, surname: resp.doctor_info.doctor_surname, special_th: resp.doctor_info.doctor_specialist_th, special_en: resp.doctor_info.doctor_specialist_en, clinic_th: resp.doctor_info.doctor_clinic_th, clinic_en: resp.doctor_info.doctor_clinic_en }
                setSelectedOption(option)
                setSpecialistDr(resp.doctor_info.doctor_specialist_th || resp.doctor_info.doctor_specialist_en || '')
                setClinic(resp.doctor_info.doctor_clinic_th || resp.doctor_info.doctor_clinic_en || '')
              }

              setComparedData(comparedData => ({
                ...comparedData,
                doctor_code: resp.doctor_info.doctor_code
              }))
            } else {
              setSelectedCancel({ value: resp.cancel_info.reason_id, label: resp.cancel_info.reason })
              setCustomReason(resp.cancel_info.reason_other || '')

              setComparedData(comparedData => ({
                ...comparedData,
                cancel_reason: resp.cancel_info.reason_id,
                other_reason: resp.cancel_info.reason_other
              }))
            }
          } else {
            setComparedData({ is_success: null })
          }
        }).catch(error => {
          console.log(error.response ?? error)
          if (error.response) {
            let status = error.response.status
            if (status === 401) tokenExpire(props.handleLogout)
          }
        })

      setCancelOption(cancelOpt)
    }
    fetchData()
    saveSetting(setting => ({ ...setting, case_modified: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Select checkbox (success)
  const handleChecked = event => {
    if (event.target.id === 'bookedDoctorSuccess') {
      setChecked1(true)
      setChecked2(false)
      setSelectedCancel(null)
      setCancelReason(false)
      setCustomReason('')
    } else {
      setChecked1(false)
      setChecked2(true)
      setSelectedOption(null)
    }
  }

  // Select checkbox (unsuccess)
  const handleReasonChange = option => {
    setSelectedCancel(option)
    if (option && option.value === 1) {
      setCancelReason(true)
    } else {
      if (!option) setCancelReason(false)
      else setCancelReason(true)
      setCustomReason('')
    }
  }

  // Search doctor (select2)
  const handleSearch = async (value) => {
    cancel && cancel()
    if (value.length > 1) {
      let extraConf = {
        cancelToken: new CancelToken(
          function executor(c) {
            cancel = c
          }
        ),
        ...config
      }

      return await Axios.get(api_doctor_search + value + '*', extraConf)
        .then(res => {
          return res.data.response.map(doctor => {
            return { value: doctor.code, label: `[${doctor.code}] ${doctor.prename_th}${doctor.name_th} ${doctor.surname_th}`, prename: doctor.prename_th, name: doctor.name_th, surname: doctor.surname_th, special_th: doctor.special_th, special_en: doctor.special_en, clinic_th: doctor.clinic_th, clinic_en: doctor.clinic_en }
          })
        }).catch(error => {
          if (!Axios.isCancel(error)) {
            if (error.response && error.response.status === 401) tokenExpire(props.handleLogout)
          }
          return null
        })
    }
  }

  // Select doctor
  const handleFilterChange = selectedOption => {
    // check form modified
    if (comparedData.is_success === null) {
      saveSetting(setting => ({ ...setting, case_modified: selectedOption !== null }))
    } else if (comparedData.is_success === true) {
      if (selectedOption) {
        if (comparedData.doctor_code) saveSetting(setting => ({ ...setting, case_modified: (comparedData.doctor_code + '' !== selectedOption.value + '') }))
        else saveSetting(setting => ({ ...setting, case_modified: true }))
      } else {
        saveSetting(setting => ({ ...setting, case_modified: comparedData.doctor_code !== null }))
      }
    }

    // set option
    setSelectedOption(selectedOption)
    setSpecialistDr(selectedOption ? (selectedOption.special_th || selectedOption.special_en || '') : '')
    setClinic(selectedOption ? (selectedOption.clinic_th || selectedOption.clinic_en || '') : '')
  }

  // Change page
  const handleChangePage = () => {
    let updated = false
    if ('doctor_code' in comparedData) {
      if (selectedOption)
        updated = !(selectedOption.value === comparedData.doctor_code)
      else
        updated = comparedData.doctor_code !== null
    } else if (comparedData.is_success === null) {
      // new case
      updated = (checked2 || selectedOption !== null)
    }
    formCompared(updated)
  }

  // Clear form data
  const handleClearForm = () => {
    doctorForm.current.reset()
    setSelectedOption(null)
    setChecked1(true)
    setChecked2(false)
    setSelectedCancel(null)
    setCancelReason(false)
    setCustomReason('')
    setSpecialistDr('')
    setClinic('')
  }

  // Form submit
  const doctorForm = useRef(null)
  const handleFormSubmit = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Loading . . .?',
      text: 'กรุณารอสักครู่',
      imageUrl: THLoad,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })
    const form = doctorForm.current
    let bookedDoctorSuccess = form['bookedDoctorSuccess'].checked
    if (!bookedDoctorSuccess) {
      if (!selectedCancel) {
        Swal.fire('เกิดข้อผิดพลาด', 'กรุณาระบุเหตุผลให้ครบถ้วน', 'error')
        return false
      }
    }

    const data = bookedDoctorSuccess ? {
      'is_success': true,
      'code': selectedOption ? selectedOption.value : '',
      'prename': selectedOption ? selectedOption.prename : '',
      'name': selectedOption ? selectedOption.name : '',
      'surname': selectedOption ? selectedOption.surname : '',
      'specialist_th': selectedOption ? selectedOption.special_th : '',
      'specialist_en': selectedOption ? selectedOption.special_en : '',
      'clinic_th': selectedOption ? selectedOption.clinic_th : '',
      'clinic_en': selectedOption ? selectedOption.clinic_en : ''
    } : {
        'is_success': false,
        'reason_id': selectedCancel.value,
        'other_reason': form['customReason'].value
      }
    Object.keys(data).forEach(key => (key !== 'is_success' && !data[key]) && delete data[key])

    if (formMethod === 'post') {
      Axios.post(api_doctor_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'บันทึกการประสานงานแพทย์เรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            if (form['bookedDoctorSuccess'].checked) {
              history.push('./ambulance')
            } else {
              saveSetting(setting => ({ ...setting, case_id: 0, case_modified: false }))
              history.push('./dashboard')
            }
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = doctorLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "บันทึกการประสานงานแพทย์ไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
        })
    } else if (formMethod === 'put') {
      Axios.put(api_doctor_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'แก้ไขการประสานงานแพทย์เรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            if (form['bookedDoctorSuccess'].checked) {
              setBlockedPatient(true)
            } else {
              saveSetting(setting => ({ ...setting, case_id: 0, case_modified: false }))
              history.push('./dashboard')
            }
          })
        }).catch(error => {
          if (error.response) {
            console.log(error.response)
            if (error.response.status === 401) tokenExpire(props.handleLogout)
            else {
              const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
              let errorDetail = ""
              if (error.response.data.reason.errors) {
                const errors = error.response.data.reason.errors
                if (errors.length > 0) {
                  errors.forEach(element => {
                    const field = doctorLabelError(element.param)
                    errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                  });
                } else errorDetail = initMsgError
              } else errorDetail = initMsgError
              Swal.fire({
                title: "แก้ไขการประสานงานแพทย์ไม่สำเร็จ!",
                html: `${errorDetail}`,
                imageUrl: THError,
                allowOutsideClick: false,
                allowEscapeKey: false,
              })
            }
          } else {
            console.log(error)
            Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถแก้ไขข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
          }
        })
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Modal opened
  const openModal = () => {
    async function fetchReason() {
      let reasonOption = await Axios.get(api_cancel_case, config)
        .then(res => {
          return res.data.response.map(reason => {
            if (reason.detail) {
              let extraOption = reason.detail.map(detail => {
                return { value: detail.id, label: detail.name, description: detail.description }
              })
              setExtraCondition(extraOption)
            }
            return { value: reason.id, label: reason.reason }
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })
      setModalOption(reasonOption)
      setUseModal(true)
    }
    fetchReason()
  }

  // Modal closed
  const closeModal = () => {
    setUseModal(false)
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page  

  return (
    <Content handleLogout={props.handleLogout}>
      <div className='main-screen'>
        {/* Title */}
        <label className='main-title'>สถานะเคส - ประสานงานแพทย์</label>

        {/* Menu */}
        <Menu images={imageList} />
        <div className='custom-spacing' />

        <form ref={doctorForm} onSubmit={handleFormSubmit}>
          <Fieldset title='ประสานงานแพทย์'>
            <CustomInput type="checkbox" id="bookedDoctorSuccess" label="สำเร็จ" onChange={handleChecked} checked={checked1} {...(blockedPatient || flagRadio ? { disabled: 'disabled' } : {})} />
            <div className='patient-name-field' style={{ marginBottom: 50 }}>
              <FormGroup>
                <Label>แพทย์ผู้ดูแล</Label>
                <AsyncSelect
                  value={selectedOption}
                  onChange={handleFilterChange}
                  loadOptions={handleSearch}
                  placeholder='ค้นหาจากชื่อ, นามสกุล หรือ รหัสของแพทย์'
                  isClearable={true}
                  isDisabled={checked2 || blockedPatient}
                />
              </FormGroup>
              <FormGroup>
                <Label>แพทย์ผู้เชี่ยวชาญ</Label>
                <Input type='text' name='specialist' id='specialist' value={specialistDr} placeholder='แพทย์ผู้เชี่ยวชาญ' disabled />
              </FormGroup>
              <FormGroup>
                <Label>คลีนิค</Label>
                <Input type='text' name='clinic' id='clinic' value={clinic} placeholder='คลีนิค' disabled />
              </FormGroup>
            </div>

            <CustomInput type="checkbox" id="bookedDoctorFailed" label="ไม่สำเร็จ" onChange={handleChecked} checked={checked2} {...(blockedPatient || flagRadio ? { disabled: 'disabled' } : {})} />
            <div className='patient-name-field'>
              <FormGroup>
                <Label>เหตุผล</Label>
                <Select
                  value={selectedCancel}
                  onChange={handleReasonChange}
                  options={cancelOption}
                  placeholder='เลือกเหตุผล'
                  isClearable={true}
                  isDisabled={checked1 || blockedPatient}
                  id='selectUnsuccess'
                  name='selectUnsuccess'
                />
              </FormGroup>

              <FormGroup>
                <Label for='customReason'>อื่น ๆ</Label>
                <Input type='text' name='customReason' id='customReason' placeholder='กรุณากรอกเหตุผล' {...(cancelReason === true ? { required: 'required' } : { disabled: 'disabled' })} onChange={e => setCustomReason(e.target.value)} value={customReason} />
              </FormGroup>
            </div>
          </Fieldset>

          {blockedPatient ? (
            <Row style={{ margin: '20px 0' }}>
              <Col>
                <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
              </Col>
              <Col>
                <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='แก้ไข' align='left' onClick={e => { setBlockedPatient(false); setFormMethod('put') }} />
              </Col>
            </Row>
          ) : (
              <div>
                <Row style={{ margin: '20px 0' }}>
                  <Col>
                    <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
                  </Col>
                  <Col>
                    <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='ล้างฟอร์ม' align='left' onClick={handleClearForm} />
                  </Col>
                </Row>
                <Row style={{ margin: '20px 0' }}>
                  <Col>
                    <Button styles='danger' width='150' height='75' icon='TimesCircle' unit='px' text='ยกเลิกเคส' align='right' onClick={openModal} />
                  </Col>
                  <Col>
                    <Button type='submit' styles='primary' width='150' height='75' icon='Save' unit='px' text='บันทึก' align='left' />
                  </Col>
                </Row>
              </div>
            )}
        </form>
        <Modal show={useModal} closeModal={closeModal} option={modalOption} extra={extraCondition} />
      </div>
    </Content>
  )
}
