import React, { useState, useEffect, useContext } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import ReactTooltip from 'react-tooltip'
import Swal from 'sweetalert2'
import { MDBDataTableV5 } from 'mdbreact';
import { Link } from 'react-router-dom'
// import { Row, Col, Table } from 'reactstrap'
import { Row, Col } from 'reactstrap'

import Button from '../../../../Components/Buttons/ButtonNormal'
import Content from '../../../../Components/Pages/TopNav/Content'
import Modal from '../../../../Components/Modals/Report'
import { convertDate } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'
import THError2 from '../../../../Images/error2.png'
import './index.css'

const processDict = {
  1: 'register',
  2: 'bed',
  3: 'doctor',
  4: 'ambulance',
  5: 'summary',
  6: 'summary'
}

export default function Dashboard(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Declare variables
  const { apis, saveSetting } = useContext(StoreContext)             // Global variables by useContext

  // URL API
  const api_dashboard = apis.api_url + apis.api_port + '/api/dashboard/allcase'

  // Axios config
  let config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  // Datatable column header
  const datatableCol = [
    // {
    //   label: 'เลขที่',
    //   field: 'refno'
    // },
    {
      label: "HN",
      field: 'hn'
    },
    {
      label: 'ชื่อผู้ป่วย',
      field: 'patient'
    },
    {
      label: 'ชื่อญาติ',
      field: 'relative'
    },
    {
      label: 'เบอร์ติดต่อ',
      field: 'tel',
      sort: 'disabled'
    },
    {
      label: 'สร้างโดย',
      field: 'creator'
    },
    {
      label: 'วัน-เวลาที่สร้าง',
      field: 'date'
    },
    {
      label: 'อยู่ในขั้นตอน',
      field: 'status',
      sort: 'disabled'
    },
    {
      label: 'การส่งข้อความ',
      field: 'sms',
      sort: 'disabled'
    },
    {
      label: '',
      field: 'update',
      sort: 'disabled'
    }
  ]

  // Datetime prototype
  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function (days) {
    let date = new Date(this.valueOf())
    date.setDate(date.getDate() + days)
    return date
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Initialize State

  // Datatable state
  const [datatable, setDatatable] = useState({})

  // Filter select menu (duration)
  const [selOptDuration, setSelOptDuration] = useState({ value: 'all', label: 'วัน-เวลาทั้งหมด' })
  const [optDuration] = useState([
    { value: 'all', label: 'วัน-เวลาทั้งหมด' },
    { value: '1', label: '1 วันล่าสุด' },
    { value: '2', label: '2 วันล่าสุด' },
    { value: '3', label: '3 วันล่าสุด' },
    { value: '7', label: '7 วันล่าสุด' }
  ])

  // Filter select menu (process)
  const [selOptStatus, setSelOptStatus] = useState({ value: 'all', label: 'สถานะทั้งหมด' })
  const [optStatus] = useState([
    { value: 'all', label: 'สถานะทั้งหมด' },
    { value: 'จองเตียง', label: 'จองเตียง' },
    { value: 'ประสานแพทย์', label: 'ประสานแพทย์' },
    { value: 'ประสานส่งตัว', label: 'ประสานส่งตัว' },
    { value: 'กำลังส่งตัว', label: 'กำลังส่งตัว' },
    { value: 'สำเร็จ', label: 'สำเร็จ' },
    { value: 'ไม่สำเร็จ', label: 'ไม่สำเร็จ' },
    { value: 'ยกเลิก', label: 'ยกเลิก' }
  ])

  // Modal state
  const [useModal, setUseModal] = useState(false)
  const [message, setMessage] = useState(null)

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Handle event

  // On page loaded, get data
  useEffect(() => {
    let startDate = new Date(-8640000000000000)
    if (selOptDuration.value !== 'all') {
      startDate = new Date().addDays(selOptDuration.value * (-1))
    }

    Axios.get(api_dashboard, config)
      .then(res => {
        if (res.status === 200) {
          let patientRows = res.data.response.filter(patient => {
            if (selOptStatus.value === 'all') {
              return new Date(patient.create_datetime).getTime() > startDate.getTime()
            } else {
              return (patient.process === selOptStatus.value) && (new Date(patient.create_datetime).getTime() > startDate.getTime())
            }
          }).map(patient => {
            return {
              // refno: patient.case_no,
              hn: patient.patient_info.hn ? patient.patient_info.hn : "-",
              patient: [patient.patient_info.prename + patient.patient_info.name, patient.patient_info.surname].join(' '),
              relative: patient.relative_info.name,
              tel: patient.relative_info.phone,
              creator: patient.create_by ?? '-',
              date: convertDate(patient.create_datetime).datetime,
              status: (<p className={`sms-details ${patient.process === 'ยกเลิก' ? 'danger' : patient.process === 'สำเร็จ' ? 'success' : patient.process === 'ไม่สำเร็จ' ? 'orange' : 'info'}`}>{patient.process}</p>),
              sms: (
                <div>
                  <p data-tip className='sms-details' data-for={`sms_details${patient.refer_id}`}>รายละเอียด</p>
                  <ReactTooltip id={`sms_details${patient.refer_id}`} type={(patient.sms_info.phone_status === 0 || patient.sms_info.phone_reserve_status === 0) ? 'success' : 'error'}>
                    <span>แจ้งโรงพยาบาลต้นทาง : {patient.sms_info.hospital_description || '-'}</span><br />
                    <span>แจ้งญาติผู้ป่วย (เบอร์ที่ 1) : {patient.sms_info.phone_description || '-'}</span><br />
                    <span>แจ้งญาติผู้ป่วย (เบอร์ที่ 2) : {patient.sms_info.phone_reserve_description || '-'}</span>
                  </ReactTooltip>
                </div>
              ),
              update: (
                (patient.process === 'ยกเลิก') ?
                  <Button styles='secondary' text='รายละเอียด' width='120' unit='px' onClick={() => openModal(patient)} />
                  :
                  <Link to={`./${processDict[(patient.process === 'ไม่สำเร็จ' ? patient.unsuccess_process_id - 1 : patient.process_id)]}`}>
                    <Button styles='warning' text='อัพเดท' width='120' unit='px' onClick={e => { saveSetting(setting => ({ ...setting, case_id: patient.refer_id, case_modified: false })) }} />
                  </Link>
              )
            }
          })

          setDatatable({
            columns: datatableCol,
            rows: patientRows
          })
        }
      }).catch(error => {
        console.log(error.response ?? error)
        Swal.fire({
          title: 'หมดเวลาการใช้งาน!',
          text: 'กรุณาล็อกอินเพื่อเข้าใช้งานใหม่อีกครั้ง',
          imageUrl: THError2,
          allowOutsideClick: false,
          confirmButtonText: 'ยืนยัน',
        }).then(result => {
          if (result.value === true) {
            sessionStorage.clear('staffJsonWebToken')
            sessionStorage.clear('staffSessionExpired')
            sessionStorage.clear('staffName')
            saveSetting({
              case_id: 0,
              case_modified: false,
              ajaxTimeOut: 60000,
              intervalTimeOut: 60000,
              recaptchaSiteKey: "6Lf3VAAVAAAAAPmVKVrsxRXzxbuh9ZL94LrsS2uv"
            })
            props.handleLogout()
          }
        })
      })
    saveSetting(setting => ({ ...setting, case_modified: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selOptDuration, selOptStatus])

  // Filter duration
  const handleSelDuration = selOption => {
    setSelOptDuration(selOption)
  }

  // Filter process
  const handleSelStatus = selOption => {
    setSelOptStatus(selOption)
  }

  // Modal opened
  const openModal = (patient) => {
    setMessage(patient)
    setUseModal(true)
  }

  // Modal closed
  const closeModal = () => setUseModal(false)

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page

  return (
    <Content handleLogout={props.handleLogout}>
      <div className='main-screen'>
        {/* Title */}
        <label style={{ margin: '20px 0' }}>รายการเคส Refer-In</label>

        {/* Filter Menu */}
        <Row style={{ margin: '0 15px' }}>
          <Col xs='4' style={{ textAlign: 'left' }}>
            <Link to='./register'>
              <Button styles='success' width='125' unit='px' text='สร้างเคสใหม่ +' onClick={() => { saveSetting(setting => ({ ...setting, case_id: -1, case_modified: false })) }} />
            </Link>
          </Col>
          <Col xs='4' style={{ textAlign: 'right' }}>
            <Row>
              <Col xs='4' style={{ lineHeight: 2.4 }}>Case In:</Col>
              <Col xs='8'>
                <Select
                  value={selOptDuration}
                  onChange={handleSelDuration}
                  options={optDuration}
                />
              </Col>
            </Row>
          </Col>
          <Col xs='4' style={{ textAlign: 'right' }}>
            <Row>
              <Col xs='4' style={{ lineHeight: 2.4 }}>Status:</Col>
              <Col xs='8'>
                <Select
                  value={selOptStatus}
                  onChange={handleSelStatus}
                  options={optStatus}
                />
              </Col>
            </Row>
          </Col>
        </Row>

        {/* Table Data */}
        <MDBDataTableV5 hover
          responsive
          entriesOptions={[5, 10, 15, 20]}
          entries={10}
          data={datatable}
          searchTop searchBottom={false}
          style={{ textAlign: 'left' }}
        />

        <Modal show={useModal} closeModal={closeModal} message={message} />
      </div>
    </Content>
  )
}
