import React, { useState, useEffect, useRef, useContext } from 'react'
import Axios from 'axios'
import Select from 'react-select'
import Swal from 'sweetalert2'
import { CustomInput, FormGroup, Label, Input, Row, Col } from 'reactstrap'

import Button from '../../../../Components/Buttons/ButtonNormal'
import Content from '../../../../Components/Pages/TopNav/Content'
import Fieldset from '../../../../Components/FieldSet'
import history from '../../../../history'
import Menu from '../../../../Components/StepProcessBar'
import Modal from '../../../../Components/Modals/Cancellation'
import THLoad from '../../../../Images/loading.png'
import THError from '../../../../Images/error.png'
import { formCompared, importImageAll, bedLabelError } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'


export default function Bed(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Declare variables
  const { setting, apis, saveSetting, tokenExpire } = useContext(StoreContext)             // Global variables by useContext

  // URL API
  const api_bed_insert = apis.api_url + apis.api_port + '/api/bed/'
  const api_division = api_bed_insert + 'division'
  const api_cancel_reason = api_bed_insert + 'reason'
  const api_process_bed = apis.api_url + apis.api_port + `/api/dashboard/process/${setting.case_id}?destination=bed`
  const api_cancel_case = apis.api_url + apis.api_port + '/api/cancel/reason'

  // Axios config
  const config = {
    headers: {
      'Authorization': 'Bearer ' + sessionStorage.getItem('staffJsonWebToken')
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Initialize State

  // Checkbox
  const [checked1, setChecked1] = useState(true)
  const [checked2, setChecked2] = useState(false)

  // Division option
  const [division, setDivision] = useState([])
  const [building, setBuilding] = useState(null)
  const [floor, setFloor] = useState(null)
  const [room, setRoom] = useState(null)
  const [roomNo, setRoomNo] = useState('')
  const [selectedDiv, setSelectedDiv] = useState(null)

  // Cancel option
  const [cancelOption, setCancelOption] = useState([])
  const [selectedCancel, setSelectedCancel] = useState(null)
  const [cancelReason, setCancelReason] = useState(false)
  const [customReason, setCustomReason] = useState('')

  // Edit form state
  const [formMethod, setFormMethod] = useState('post')
  const [blockedPatient, setBlockedPatient] = useState(false)
  const [comparedData, setComparedData] = useState({})
  const [flagRadio, setFlagRadio] = useState(false)

  // Modal state
  const [useModal, setUseModal] = useState(false)
  const [modalOption, setModalOption] = useState([])
  const [extraCondition, setExtraCondition] = useState([])

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render menu
  const [processState, setProcessState] = useState(1)
  const imageArr = importImageAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  const imageList = ['register', 'bed', 'doctor', 'ambulance', 'summary'].map((menu, index) => {
    if (index > processState)
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState }
    else
      return { id: index + 1, alt: menu, src: imageArr[index + (index < processState ? 5 : 0)], active: index <= processState, link: `./${menu}` }
  })

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Handle event

  // On page loaded, get data
  useEffect(() => {
    async function fetchData() {
      // Get division option
      let section = await Axios.get(api_division, config)
        .then(res => {
          return res.data.response.map(division => {
            return { value: division.id, label: division.division_name, building: division.detail.building, floor: division.detail.floor, room: division.have_room }
          })
        }).catch(error => {
          console.log(error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })

      // Get cancel option
      let denied = await Axios.get(api_cancel_reason, config)
        .then(res => {
          if (res.status === 200) {
            return res.data.response.map(reason => {
              return { value: reason.id, label: reason.reason }
            })
          }
        }).catch(error => {
          console.log(error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })

      // On page loaded, get data
      await Axios.get(api_process_bed, config)
        .then(res => {
          if (res.data.response.have_data) {
            setBlockedPatient(true)
            let resp = res.data.response.data
            // console.log(resp)

            setChecked1(resp.is_success)
            setChecked2(!resp.is_success)
            setComparedData({ is_success: resp.is_success })
            if (resp.is_success) {
              if (resp.patient_info.unsuccess_process_id)
                setProcessState(resp.patient_info.unsuccess_process_id - 2)
              else {
                setProcessState(resp.patient_info.status_id - 1)
                setFlagRadio(resp.patient_info.status_id > 3)
              }

              if (resp.bed_info.division_id) {
                setSelectedDiv({ value: resp.bed_info.division_id, label: resp.bed_info.division, building: resp.bed_info.building, floor: resp.bed_info.floor, room: resp.bed_info.have_room })
                setBuilding({ value: resp.bed_info.building, label: resp.bed_info.building })
                setFloor({ value: resp.bed_info.floor, label: resp.bed_info.floor })
                setRoom(resp.bed_info.have_room)
                setRoomNo(resp.bed_info.room_no ?? '')
              }

              setComparedData(comparedData => ({
                ...comparedData,
                division_id: resp.bed_info.division_id,
                room_no: resp.bed_info.room_no ?? ''
              }))
            } else {
              setSelectedCancel({ value: resp.cancel_info.reason_id, label: resp.cancel_info.reason })
              setCustomReason(resp.cancel_info.reason_other || '')

              setComparedData(comparedData => ({
                ...comparedData,
                cancel_reason: resp.cancel_info.reason_id,
                other_reason: resp.cancel_info.reason_other
              }))
            }
          } else {
            setComparedData({ is_success: null })
          }
        }).catch(error => {
          console.log(error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
        })

      setDivision(section)
      setCancelOption(denied)
    }
    fetchData()
    saveSetting(setting => ({ ...setting, case_modified: false }))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Select checkbox (success)
  const handleChecked = event => {
    if (event.target.id === 'bookedBedSuccess') {
      setChecked1(true)
      setChecked2(false)
      setSelectedCancel(null)
      setCancelReason(false)
      setCustomReason('')
    } else {
      setChecked1(false)
      setChecked2(true)
      setSelectedDiv(null)
      setBuilding(null)
      setFloor(null)
    }
  }

  // Select checkbox (unsuccess)
  const handleReasonChange = option => {
    setSelectedCancel(option)
    if (option && option.value === 1) {
      setCancelReason(true)
    } else {
      if (!option) setCancelReason(false)
      else setCancelReason(true)
      setCustomReason('')
    }
  }

  // Change division
  const handleDivisionChange = division => {
    // set option
    setSelectedDiv(division)
    setBuilding(division ? { value: division.building, label: division.building } : null)
    setFloor(division ? { value: division.floor, label: division.floor } : null)
    setRoom(division ? division.room : null)
    setRoomNo('')

    // check form modified (cannot check on room changed because afraid that overwrite save contextStore)
    if (comparedData.is_success === null) {
      saveSetting(setting => ({ ...setting, case_modified: (checked2 || division !== null) }))
    } else if (comparedData.is_success === true) {
      if (division) {
        if (comparedData.division_id) {
          saveSetting(setting => ({ ...setting, case_modified: (checked1 && (division.value + '' !== comparedData.division_id + '')) }))
        } else {
          saveSetting(setting => ({ ...setting, case_modified: true }))
        }
      } else {
        saveSetting(setting => ({ ...setting, case_modified: comparedData.division_id !== null }))
      }
    }
  }

  // Change page
  const handleChangePage = () => {
    let updated = false
    if (comparedData.is_success === null) {
      // new case
      updated = (checked2 || selectedDiv !== null)
    } else {
      const form = bedForm.current
      if (form['selectDivision']) updated = !((checked1 === comparedData.is_success) && (selectedDiv.value + '' === comparedData.division_id + ''))
    }
    formCompared(updated)
  }

  // Edit form data
  const handleEditForm = () => {
    setBlockedPatient(false)
    setFormMethod('put')
  }

  // Clear form data
  const handleClearForm = () => {
    setChecked1(true)
    setChecked2(false)

    setSelectedDiv(null)
    setBuilding(null)
    setFloor(null)

    setSelectedCancel(null)
    setCancelReason(false)
    setCustomReason('')
  }

  // Form submit
  const bedForm = useRef(null)
  const handleFormSubmit = event => {
    event.preventDefault()
    Swal.fire({
      title: 'Loading . . .?',
      text: 'กรุณารอสักครู่',
      imageUrl: THLoad,
      allowOutsideClick: false,
      allowEscapeKey: false,
      allowEnterKey: false,
      showConfirmButton: false
    })
    const form = bedForm.current
    let bookedBedSuccess = form['bookedBedSuccess'].checked
    if (!bookedBedSuccess) {
      if (!selectedCancel) {
        Swal.fire('เกิดข้อผิดพลาด', 'กรุณาระบุเหตุผลให้ครบถ้วน', 'error')
        return false
      }
    }

    const data = bookedBedSuccess ? {
      'is_success': true,
      'division_id': selectedDiv ? selectedDiv.value : null,
      'room_no': roomNo
    } : {
        'is_success': false,
        'reason_id': selectedCancel.value,
        'other_reason': form['customReason'].value
      }
    Object.keys(data).forEach(key => (key !== 'is_success' && !data[key]) && delete data[key])
    console.log(data)

    if (formMethod === 'post') {
      Axios.post(api_bed_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'บันทึกการจองเตียงผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            if (form['bookedBedSuccess'].checked) {
              history.push('./doctor')
            } else {
              saveSetting(setting => ({ ...setting, case_id: 0, case_modified: false }))
              history.push('./dashboard')
            }
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          else {
            const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
            let errorDetail = ""
            if (error.response.data.reason.errors) {
              const errors = error.response.data.reason.errors
              if (errors.length > 0) {
                errors.forEach(element => {
                  const field = bedLabelError(element.param)
                  errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                });
              } else errorDetail = initMsgError
            } else errorDetail = initMsgError
            Swal.fire({
              title: "บันทึกการจองเตียงผู้ป่วยไม่สำเร็จ!",
              html: `${errorDetail}`,
              imageUrl: THError,
              allowOutsideClick: false,
              allowEscapeKey: false,
            })
          }
          // Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถบันทึกข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
        })
    } else if (formMethod === 'put') {
      Axios.put(api_bed_insert + setting.case_id, data, config)
        .then(res => {
          Swal.fire({
            title: 'Success',
            text: 'แก้ไขการจองเตียงผู้ป่วยเรียบร้อย',
            icon: 'success',
            allowOutsideClick: false,
            timer: 2000
          }).then(() => {
            if (form['bookedBedSuccess'].checked) {
              setBlockedPatient(true)
            } else {
              saveSetting(setting => ({ ...setting, case_id: 0, case_modified: false }))
              history.push('./dashboard')
            }
          })
        }).catch(error => {
          if (error.response) {
            console.log(error.response)
            if (error.response.status === 401) tokenExpire(props.handleLogout)
            else {
              const initMsgError = `<strong>กรุณาติดต่อ IT</strong>`
              let errorDetail = ""
              if (error.response.data.reason.errors) {
                const errors = error.response.data.reason.errors
                if (errors.length > 0) {
                  errors.forEach(element => {
                    const field = bedLabelError(element.param)
                    errorDetail += `<strong>${field} : </strong>${element.msg}<br/>`
                  });
                } else errorDetail = initMsgError
              } else errorDetail = initMsgError
              Swal.fire({
                title: "แก้ไขการจองเตียงผู้ป่วยไม่สำเร็จ!",
                html: `${errorDetail}`,
                imageUrl: THError,
                allowOutsideClick: false,
                allowEscapeKey: false,
              })
            }
          } else {
            console.log(error)
            Swal.fire('เกิดข้อผิดพลาด!', 'ไม่สามารถแก้ไขข้อมูลผู้ป่วยได้ กรุณาติดต่อ IT', 'error')
          }
        })
    }
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Modal opened
  const openModal = () => {
    async function fetchReason() {
      let reasonOption = await Axios.get(api_cancel_case, config)
        .then(res => {
          return res.data.response.map(reason => {
            if (reason.detail) {
              let extraOption = reason.detail.map(detail => {
                return { value: detail.id, label: detail.name, description: detail.description }
              })
              setExtraCondition(extraOption)
            }
            return { value: reason.id, label: reason.reason }
          })
        }).catch(error => {
          console.log(error.response ?? error)
          let status = error.response.status
          if (status === 401) tokenExpire(props.handleLogout)
          return []
        })
      setModalOption(reasonOption)
      setUseModal(true)
    }
    fetchReason()
  }

  // Modal closed
  const closeModal = () => {
    setUseModal(false)
  }

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page

  return (
    <Content handleLogout={props.handleLogout}>
      <div className='main-screen'>
        {/* Title */}
        <label className='main-title'>สถานะเคส - จองเตียงผู้ป่วย</label>

        {/* Menu */}
        <Menu images={imageList} />
        <div className='custom-spacing' />

        <form ref={bedForm} onSubmit={handleFormSubmit}>
          <Fieldset title='จองเตียงผู้ป่วย'>
            <CustomInput type="checkbox" id="bookedBedSuccess" label="สำเร็จ" onChange={handleChecked} checked={checked1} {...(blockedPatient || flagRadio ? { disabled: 'disabled' } : {})} />
            <div className='patient-name-field' style={{ marginBottom: 50 }}>
              <FormGroup>
                <Label>หน่วยงาน</Label>
                <Select
                  onChange={handleDivisionChange}
                  options={division}
                  value={selectedDiv}
                  placeholder='หน่วยงาน'
                  isDisabled={checked2 || blockedPatient}
                  isClearable={true}
                  id='selectDivision'
                  name='selectDivision'
                />
              </FormGroup>

              <FormGroup>
                <Label>อาคาร</Label>
                <Select
                  value={building}
                  options={building}
                  placeholder='อาคาร'
                  isDisabled={true}
                />
              </FormGroup>

              <FormGroup>
                <Label>ชั้นที่</Label>
                <Select
                  value={floor}
                  options={floor}
                  placeholder='ชั้นที่'
                  isDisabled={true}
                />
              </FormGroup>

              <FormGroup>
                <Label for='roomNo'>เลขที่ห้อง</Label>
                <Input type='text' name='roomNo' id='roomNo' onChange={e => setRoomNo(e.target.value)} value={roomNo} placeholder='เลขที่ห้อง' {...(blockedPatient || !room ? { disabled: 'disabled' } : {})} />
              </FormGroup>
            </div>

            <CustomInput type="checkbox" id="bookedBedFailed" label="ไม่สำเร็จ" onChange={handleChecked} checked={checked2} {...(blockedPatient || flagRadio ? { disabled: 'disabled' } : {})} />
            <div className='patient-name-field'>
              <FormGroup>
                <Label>เหตุผล</Label>
                <Select
                  value={selectedCancel}
                  onChange={handleReasonChange}
                  options={cancelOption}
                  placeholder='เลือกเหตุผล'
                  isDisabled={checked1 || blockedPatient}
                  isClearable={true}
                  id='selectUnsuccess'
                  name='selectUnsuccess'
                />
              </FormGroup>

              <FormGroup>
                <Label for='customReason'>อื่น ๆ</Label>
                <Input type='text' name='customReason' id='customReason' placeholder='กรุณากรอกเหตุผล' {...(cancelReason === true ? { required: 'required' } : { disabled: 'disabled' })} onChange={e => setCustomReason(e.target.value)} value={customReason} />
              </FormGroup>
            </div>
          </Fieldset>

          {blockedPatient ? (
            <Row style={{ margin: '20px 0' }}>
              <Col>
                <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
              </Col>
              <Col>
                <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='แก้ไข' align='left' onClick={handleEditForm} />
              </Col>
            </Row>
          ) : (
              <div>
                <Row style={{ margin: '20px 0' }}>
                  <Col>
                    <Button styles='success' width='150' height='75' icon='Home' unit='px' text='หน้าหลัก' align='right' onClick={handleChangePage} />
                  </Col>
                  <Col>
                    <Button styles='warning' width='150' height='75' icon='Edit' unit='px' text='ล้างฟอร์ม' align='left' onClick={handleClearForm} />
                  </Col>
                </Row>
                <Row style={{ margin: '20px 0' }}>
                  <Col>
                    <Button styles='danger' width='150' height='75' unit='px' icon='TimesCircle' text='ยกเลิกเคส' align='right' onClick={openModal} />
                  </Col>
                  <Col>
                    <Button type='submit' styles='primary' width='150' height='75' unit='px' icon='Save' text='บันทึก' align='left' />
                  </Col>
                </Row>
              </div>
            )}
        </form>
        <Modal show={useModal} closeModal={closeModal} option={modalOption} extra={extraCondition} />
      </div>
    </Content>
  )
}
