import React, { useState, useEffect, useRef, useContext, useCallback } from 'react'
import Content from '../../../../Components/Pages/TopNav/Content'
import StepProcessBar from '../../../../Components/StepProcessBar'
import FieldSet from '../../../../Components/FieldSet'
import thankImage from '../../../../Images/thank2.png'
import Axios from 'axios'
// import { convertDate } from '../../../../Utilities'
import { StoreContext } from '../../../../Context/Store'
import Swal from 'sweetalert2'
import Button from '../../../../Components/Buttons/ButtonNormal'
import THError from '../../../../Images/error2.png'
import './index.css'
import RelativesFooter from '../../../../Components/Footer/RelativesFooter'

const msg_server_error = "ขออภัยค่ะ ขณะนี้ระบบไม่สามารถให้บริการได้ชั่วคราว";
const msg_contact = "หากท่านมีข้อสอบถามสามารถติดต่อได้ที่ โทร 0-2487-2000 ต่อ 2273, 2274 (พยาบาล Refer)";

const Dashboard = (props) => {
  const { setting, apis } = useContext(StoreContext)
  const api = apis.cus_url + apis.cus_port
  // Get all images menu
  let _referralCase = JSON.parse(sessionStorage.getItem("referral_case"))
  let _referralCaseList = JSON.parse(sessionStorage.getItem("referral_case_list"))

  const CancelToken = Axios.CancelToken;
  let cancel;

  const [referralCaseState, setReferralCaseState] = useState(_referralCase || null)
  const [referralCaseListState, setReferralCaseListState] = useState(_referralCaseList || null)
  const [lastProgressState, setLastProgressState] = useState(referralCaseState.current_process_id || 0)
  const [currentProgressState, setCurrentProgressState] = useState(referralCaseState.current_process_id || 0)
  const [phonNumberState] = useState(sessionStorage.getItem("customerPhone") || null)
  const [loopState, setLoopState] = useState(false)
  const imageArr = importAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  const useRefSelect = useRef(null)

  const tokenTimeout = useCallback(() => {
    let timerInterval;
    Swal.fire({
      imageUrl: THError,
      title: 'หมดเวลาเข้าใช้งานระบบ!',
      html: 'จะออกภายใน <b></b> milliseconds.',
      timer: 10000,
      timerProgressBar: true,
      onBeforeOpen: () => {
        Swal.showLoading()
        timerInterval = setInterval(() => {
          const content = Swal.getContent()
          if (content) {
            const b = content.querySelector('b')
            if (b) {
              b.textContent = Swal.getTimerLeft()
            }
          }
        }, 100)
      },
      onClose: () => {
        clearInterval(timerInterval)
        setLoopState(false)
        sessionStorage.clear()
        props.history.push("/login")
      }
    }).then((result) => {
      if (result.dismiss === Swal.DismissReason.timer) {
        // console.log('I was closed by the timer')
      }
    })
  }, [props.history])


  useEffect(() => {
    Axios.get(`${api}/api/relative/case/${phonNumberState}`, {
      headers: { Authorization: `Bearer ${sessionStorage.getItem("customerToken")}` }
    }, { timeout: setting.ajaxTimeOut })
      .then(res => {
        // console.log(res.data)
        let data = res.data.response
        setLastProgressState(data.referral_case.last_process_id)
        setCurrentProgressState(data.referral_case.last_process_id)
        setReferralCaseState(data.referral_case)
        setReferralCaseListState(data.referral_case_list)
        setLoopState(true)
      })
      .catch(err => {
        console.log(err.response)
        let status = err.response.status
        let reason = err.response.data.message
        if (status === 400) {
          if (reason.referral_error_code.trim() === "relativecaseget_err_01")
            Swal.fire({
              title: "ไม่พบ Account ผู้ใช้งานนี้!",
              text: "กรุณา refresh page หรือ login เข้าสู่ระบบใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
              imageUrl: THError,
              confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
            })
          else if (reason.referral_error_code.trim() === "relativecaseget_err_02")
            Swal.fire({
              title: "ไม่พบรายชื่อผู้ป่วยที่เลือก!",
              text: "กรุณา refresh page ใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
              imageUrl: THError,
              confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
            })
        }
        else if (status === 401) tokenTimeout()
        else if (status === 500) {
          Swal.fire({
            title: msg_server_error,
            text: msg_contact,
            imageUrl: THError,
            confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
          })
        }
      })
  }, [api, phonNumberState, tokenTimeout, setting])

  useEffect(() => {
    if (loopState === true) {
      let initTime = setInterval(() => {
        let url = ""
        if (document.getElementById("referralCaseListControlSelect").selectedIndex !== 0)
          url = `?referral_id=${useRefSelect.current.value}`
        Axios.get(`${api}/api/relative/case/${phonNumberState}${url}`, {
          headers: { Authorization: `Bearer ${sessionStorage.getItem("customerToken")}` }
        }, { timeout: setting.ajaxTimeOut })
          .then(res => {
            // console.log(res.data)
            let data = res.data.response
            if (lastProgressState === currentProgressState) {
              setCurrentProgressState(data.referral_case.last_process_id)
              setReferralCaseState(data.referral_case)
            }
            else {
              setReferralCaseState(referralCaseState => ({
                ...referralCaseState,
                last_process_msg: data.referral_case.last_process_msg,
                last_process_msg2: data.referral_case.last_process_msg2
              }))
            }
            setLastProgressState(data.referral_case.last_process_id)
            setReferralCaseListState(data.referral_case_list)
          })
          .catch(err => {
            console.log(err.response)
            let status = err.response.status
            let reason = err.response.data.message
            if (status === 400) {
              if (reason.referral_error_code.trim() === "relativecaseget_err_01")
                Swal.fire({
                  title: "ไม่พบ Account ผู้ใช้งานนี้!",
                  text: "กรุณา refresh page หรือ login เข้าสู่ระบบใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
                  imageUrl: THError,
                  confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
                })
              else if (reason.referral_error_code.trim() === "relativecaseget_err_02")
                Swal.fire({
                  title: "ไม่พบรายชื่อผู้ป่วยที่เลือก!",
                  text: "กรุณา refresh page ใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
                  imageUrl: THError,
                  confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
                })
            }
            else if (status === 401) tokenTimeout()
            else if (status === 500) {
              Swal.fire({
                title: 'ระบบขัดข้อง!',
                text: "กรุณา refresh page หรือ login เข้าสู่ระบบใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
                imageUrl: THError,
                confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
              })
            }
          })
      }, setting.intervalTimeOut)
      return () => clearInterval(initTime)
    }
  }, [api, currentProgressState, lastProgressState, loopState, phonNumberState, tokenTimeout, setting])

  function importAll(r) {
    return r.keys().map(r)
  }

  const pushLogout = () => {
    Swal.fire({
      title: 'ออกจากระบบ',
      text: "ท่านต้องการออกจากระบบใช่หรือไม่?",
      imageUrl: THError,
      showCancelButton: true,
      // confirmButtonColor: '#',
      cancelButtonColor: '#dc3545',
      confirmButtonText: '<i class="fas fa-check"></i> ตกลง',
      cancelButtonText: '<i class="fas fa-times"></i> ยกเลิก',
      reverseButtons: true
    }).then((result) => {
      if (result.value) {
        sessionStorage.clear()
        props.history.push("/login")
      } else if (
        /* Read more about handling dismissals below */
        result.dismiss === Swal.DismissReason.cancel
      ) {
      }
    })
  }

  const stepProcessHandler = (e) => {
    // console.log(useRefSelect.current.value)
    // console.log(e.currentTarget.attributes['process'].value)
    try {
      setLoopState(false)
      if (cancel !== undefined) cancel()
      Axios.get(`${api}/api/relative/case/${phonNumberState}?referral_id=${parseInt(useRefSelect.current.value)}&process_id=${parseInt(e.currentTarget.attributes['process'].value)}`,
        {
          headers: { Authorization: `Bearer ${sessionStorage.getItem("customerToken")}` },
          cancelToken: new CancelToken(function executor(c) {
            // An executor function receives a cancel function as a parameter
            cancel = c;
          })
        })
        .then(res => {
          // console.log(res.data)
          let data = res.data.response
          setReferralCaseState(data.referral_case)
          setReferralCaseListState(data.referral_case_list)
          setLastProgressState(data.referral_case.last_process_id)
          setCurrentProgressState(data.referral_case.process_id)
        })
        .catch(err => {
          console.log(err.response)
          let status = err.response.status
          let reason = err.response.data.message
          if (status === 400) {
            if (reason.referral_error_code.trim() === "relativecaseget_err_01")
              Swal.fire({
                title: "ไม่พบ Account ผู้ใช้งานนี้!",
                text: "กรุณา refresh page หรือ login เข้าสู่ระบบใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
                imageUrl: THError,
                confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
              })
            // else if (reason.referral_error_code === "relativecaseget_err_02")
            //   Swal.fire("เกิดข้อผิดพลาด!", "ยังไม่ถึง process ที่เลือก", "error")
          }
          else if (status === 401) tokenTimeout()
          else if (status === 500) {
            Swal.fire({
              title: msg_server_error,
              text: msg_contact,
              imageUrl: THError,
              confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
            })
          }
        })
        .finally(
          setLoopState(true)
        )

    } catch (error) {
      console.log(error)
      setLoopState(true)
    }
  }

  const patientChangeHandler = (e) => {
    try {
      setLoopState(false)
      if (cancel !== undefined) cancel()
      Axios.get(`${api}/api/relative/case/${phonNumberState}?referral_id=${parseInt(e.target.value)}`, {
        headers: { Authorization: `Bearer ${sessionStorage.getItem("customerToken")}` },
        cancelToken: new CancelToken(function executor(c) {
          // An executor function receives a cancel function as a parameter
          cancel = c;
        })
      })
        .then(res => {
          // console.log(res.data)
          let data = res.data.response
          // console.log(data)
          setReferralCaseState(data.referral_case)
          setReferralCaseListState(data.referral_case_list)
          setLastProgressState(data.referral_case.last_process_id)
          setCurrentProgressState(data.referral_case.last_process_id)
        })
        .catch(err => {
          console.log(err.response)
          let status = err.response.status
          let reason = err.response.data.message
          if (status === 400) {
            if (reason.referral_error_code.trim() === "relativecaseget_err_01")
              Swal.fire({
                title: "ไม่พบ Account ผู้ใช้งานนี้!",
                text: "กรุณา refresh page หรือ login เข้าสู่ระบบใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
                imageUrl: THError,
                confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
              })
            else if (reason.referral_error_code.trim() === "relativecaseget_err_02")
              Swal.fire({
                title: "ไม่พบรายชื่อผู้ป่วยที่เลือก!",
                text: "กรุณา refresh page ใหม่ ถ้าไม่ได้ให้ติดต่อ รพ.ธนบุรี HOTLINE. 1645 กด 1",
                imageUrl: THError,
                confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
              })
          }
          else if (status === 401) tokenTimeout()
          else if (status === 500) {
            Swal.fire({
              title: msg_server_error,
              text: msg_contact,
              imageUrl: THError,
              confirmButtonText: '<i class="fas fa-check"></i> ตกลง'
            })
          }
        })
        .finally(
          setLoopState(true)
        )
    } catch (error) {
      setLoopState(true)
      console.log(error)
    }
  }

  //declare variable
  let patients = null
  let patientDetail = null
  let getStatusReferCase = null
  let viewStatusReferCase = null
  let hideProgress = false


  //if useEffect get already data
  if (referralCaseState && referralCaseListState) {
    viewStatusReferCase = lastProgressState
    // const cases = (referralCaseState.current_process_id ? referralCaseState.current_process_id : referralCaseState.last_process_id) - 2
    const cases = (lastProgressState ? lastProgressState : referralCaseState.current_process_id) - 2

    // Get all menu
    const imageMenu = ['Information', 'Bed', 'Doctor', 'Ambulance', 'Success'].map((menu, index) => {
      return { id: index + 1, process: index + 2, alt: menu, src: imageArr[index + (index <= cases ? 5 : 0)], active: index <= cases }
    })

    // const patientName = "Mr.Mark Otto"

    referralCaseListState.sort(function (a, b) { return b.referral_id - a.referral_id })
    patients = (<FieldSet title="ข้อมูลผู้ป่วย" class="m-2 p-1">
      <div className="m-2">
        <select className="form-control" id="referralCaseListControlSelect" onChange={patientChangeHandler} ref={useRefSelect}>
          {referralCaseListState.map((r, index) => {
            return (
              <option key={index} value={r.referral_id}>{`${r.prename}${r.name} ${r.surname}`}</option>
            )
          })}
        </select>
      </div>
    </FieldSet>)

    if (referralCaseState.if_cancel === true || (lastProgressState === 7 || lastProgressState === 8)) hideProgress = true
    if (referralCaseState.extra)
      if (referralCaseState.extra.is_success === false) hideProgress = true

    patientDetail = (
      <FieldSet title="สถานะการดำเนินการ" class="m-2 p-1">
        {hideProgress === true ? null : <StepProcessBar images={imageMenu} onclick={stepProcessHandler} />}
        {hideProgress === true ?
          (<div className="mx-1">
            {referralCaseState.last_process_msg ?
              <React.Fragment>
                <p>{referralCaseState.last_process_msg}</p>
                <p>{referralCaseState.last_process_msg2}</p>
              </React.Fragment>
              :
              <React.Fragment>
                <p>{referralCaseState.text_for_relative}</p>
                <p>{referralCaseState.text_for_relative2}</p>
              </React.Fragment>
            }
          </div>) :
          (referralCaseState.text_for_relative ?
            <div style={{ clear: "both" }} className="pt-4 mx-1">
              <p>{referralCaseState.text_for_relative}</p>
              <p>{referralCaseState.text_for_relative2}</p>
            </div> :
            <div style={{ clear: "both" }} className="pt-4 mx-1">
              <p>{referralCaseState.last_process_msg}</p>
              <p>{referralCaseState.last_process_msg2}</p>
            </div>
          )}
      </FieldSet>
    )
    if (currentProgressState < lastProgressState) viewStatusReferCase = currentProgressState
    getStatusReferCase = (
      <div className="container">
        <div className="row">
          <div className="col">
            {patients}
          </div>
        </div>
        <div className="row">
          <div className="col">
            {patientDetail}
          </div>
        </div>
        {viewStatusReferCase === 3 && referralCaseState.extra ? (
          <div className="row">
            <div className="col">
              <FieldSet title="ข้อมูลเตียงสำหรับผู้ป่วย" class="m-2 p-1">
                <div className="text-left  mx-3">
                  <p><strong>หน่วยงาน :</strong> {referralCaseState.extra.division_name}</p>
                  <p><strong>อาคาร :</strong> {referralCaseState.extra.building_name}</p>
                  <p><strong>ชั้นที่ :</strong> {referralCaseState.extra.floor_number}</p>
                  {referralCaseState.extra.have_room_no === true ?
                    (<p><strong>ห้อง :</strong>{referralCaseState.extra.room_no === null ||
                      referralCaseState.extra.room_no === "" ? " -" : <React.Fragment> {referralCaseState.extra.room_no}</React.Fragment>}</p>)
                    : null}
                </div>
              </FieldSet>
            </div>
          </div>
        ) : null}
        {viewStatusReferCase === 5 && referralCaseState.extra ? (
          <div className="row">
            <div className="col">
              <FieldSet title="ข้อมูลการส่งตัวผู้ป่วย" class="m-2 p-1">
                <div className="text-left mx-3">
                  {referralCaseState.extra.is_pickup === true ?
                    <p><strong>วิธีส่งตัวผู้ป่วย :</strong> รพ.ธนบุรีไปรับ</p> :
                    <p><strong>วิธีส่งตัวผู้ป่วย :</strong> รพ.ต้นทางนำส่ง</p>}
                  {referralCaseState.extra.is_pickup === true ?
                    <React.Fragment>
                      <p><strong>วันที่จะถึง รพ.ต้นทาง :</strong> {referralCaseState.extra.plan_goarrive_date ? new Date(referralCaseState.extra.plan_goarrive_date).toLocaleDateString("en-GB") : '-'}</p>
                      <p><strong>เวลาที่คาดว่าจะมาถึง รพ.ต้นทาง :</strong> {referralCaseState.extra.plan_goarrive_time ? referralCaseState.extra.plan_goarrive_time.substr(0, 5) : "-"} น.</p>
                    </React.Fragment> : null}

                  <p><strong>วันที่จะถึง รพ.ธนบุรี :</strong> {referralCaseState.extra.plan_arrive_date ? new Date(referralCaseState.extra.plan_arrive_date).toLocaleDateString("en-GB") : '-'}</p>
                  <p><strong>เวลาที่คาดว่าจะถึง รพ.ธนบุรี :</strong> {referralCaseState.extra.plan_arrive_time ? referralCaseState.extra.plan_arrive_time.substr(0, 5) : '-'} น.</p>
                </div>
              </FieldSet>
            </div>
          </div>
        ) : null}
        {viewStatusReferCase === 6 && referralCaseState.extra ? (
          <div>
            <div className="row">
              <div className="col">
                <FieldSet title="ข้อมูลการส่งตัวผู้ป่วย" class="m-2 p-1">
                  <div className="text-left mx-3">
                    <p><strong>วันที่ผู้รับบริการมาถึง รพ.ธนบุรี :</strong> {referralCaseState.extra.actual_arrive_date ? new Date(referralCaseState.extra.actual_arrive_date).toLocaleDateString("en-GB") : '-'}</p>
                    <p><strong>เวลาที่ผู้รับบริการมาถึง รพ.ธนบุรี :</strong> {referralCaseState.extra.actual_arrive_time ? referralCaseState.extra.actual_arrive_time.substr(0, 5) : '-'} น.</p>
                  </div>
                </FieldSet>
              </div>
            </div>
            <div className="row">
              <div className="col">
                <br />
                <img src={thankImage} alt="" />
              </div>
            </div>
          </div>
        ) : null}
        {viewStatusReferCase === 7 || viewStatusReferCase === 8 ? (
          <div className="row">
            <div className="col">
              <img src={thankImage} alt="" />
            </div>
          </div>
        ) : null}
        <div className="row">
          <div className="col m-2">
            <Button type="button" styles="primary btn-lg btn-block" icon="SignOutAlt" onClick={pushLogout} text="ออกจากระบบ" />
          </div>
        </div>
        <div className="row">
          <div className="col">
          </div>
        </div>
      </div>
    )
  }

  return (
    <Content isCustomer={true}>
      <div className='main-screen'>
        {getStatusReferCase}
        <div className="container">
          <div className="row">
            <div className="col d-flex justify-content-center">
              <RelativesFooter />
            </div>
          </div>
        </div>
      </div>
    </Content>
  )
}

export default Dashboard;
