import React, { useEffect } from 'react'
// import { Router, Route, Switch } from 'react-router-dom';
import { Link } from 'react-router-dom'
import Content from '../../../Components/Pages/TopNav/Content'
import logo from '../../../Images/logo_thonburi_full.svg'
import splashScreen from '../../../Images/splashScreen.svg'
import './index.css'

export default function Login(props) {
  useEffect(() => {
    setTimeout(() => {
      props.history.push("/login")
    }, 3000)
  },[props.history])

  return (
    <Content>
      <Link to='/login'>
        <div className='main-screen'>
          <img src={logo} alt='logo' />
          <h2>ยินดีให้บริการ</h2>
          <img src={splashScreen} alt='splash_screen' />
        </div>
      </Link>
    </Content>
  )
}