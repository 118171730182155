import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Row, Col } from 'reactstrap'

import Button from '../../Buttons/ButtonNormal'
import { convertDate } from '../../../Utilities/'

const processDict = {
  2: 'จองเตียง',
  3: 'ประสานแพทย์',
  4: 'ประสานส่งตัว'
}

export default function Report(props) {
  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Declare variables
  const { show, closeModal, message } = props

  //=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=-=//
  // Render page

  return (
    <React.Fragment>
      <Modal isOpen={show} backdrop='static' keyboard={false} className='modal-custom modal-danger'>
        <ModalHeader toggle={closeModal}>ยกเลิกเคส Refer-In</ModalHeader>
        <ModalBody>
          <Row><Col><u>รายละเอียด</u></Col></Row>
          {
            message ? (
              <React.Fragment>
                <Row className='mb-2'>
                  <Col xs='4' style={{ textAlign: 'right' }}>ผู้ป่วย : </Col>
                  <Col xs='8'>{message.patient_info.prename}{message.patient_info.name} {message.patient_info.surname}</Col>
                </Row>
                <Row className='mb-2'>
                  <Col xs='4' style={{ textAlign: 'right' }}>เหตุผลในการยกเลิก : </Col>
                  <Col xs='8'>{message.cancel_info.other_reason ?
                    message.cancel_info.other_reason : message.cancel_info.reason === 'Condition' ?
                      `[${message.cancel_info.reason}] ${message.cancel_info.condition_detail.name}${message.cancel_info.condition_detail.description !== '-' ? ', ' + message.cancel_info.condition_detail.description : ''}` : message.cancel_info.reason
                  }</Col>
                </Row>
                <Row className='mb-2'>
                  <Col xs='4' style={{ textAlign: 'right' }}>ขั้นตอน : </Col>
                  <Col xs='8'>{processDict[message.cancel_process_id]}</Col>
                </Row>
                <Row className='mb-2'>
                  <Col xs='4' style={{ textAlign: 'right' }}>วัน-เวลา : </Col>
                  <Col xs='8'>{convertDate(message.cancel_info.cancel_datetime).datetime}</Col>
                </Row>
                <Row className='mb-2'>
                  <Col xs='4' style={{ textAlign: 'right' }}>ผู้ทำการยกเลิก : </Col>
                  <Col xs='8'>{message.cancel_info.cancel_by}</Col>
                </Row>
              </React.Fragment>
            ) : (
                <Row><Col>-</Col></Row>
              )
          }
        </ModalBody>
        <ModalFooter>
          <Row>
            <Col>
              <Button styles='warning' width='125' unit='px' text='ปิด' align='right' onClick={closeModal} />
            </Col>
          </Row>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  )

}