import React from 'react'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
import './index.css'

export default function Loading(props) {

  return (
    <React.Fragment>
      {
        props.display === 'show' ? (
          <div className='box-loading'>
            {/* <FontAwesomeIcon icon={faSyncAlt} size='3x' style={{ zIndex: 2, color: '#fff', position: 'relative', marginTop: 'calc(50% - 1.5em)' }} spin /> */}
          </div>
        ) : ''
      }
    </React.Fragment>
  )
}