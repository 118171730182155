import React, { useContext } from 'react'
import Swal from 'sweetalert2'
import { Row, Col } from 'reactstrap'
import Menu from '../../Menu/Round'
import User from '../../../Images/userunknow.jpg'
import { allowedUsage } from '../../../Utilities'
import { StoreContext } from '../../../Context/Store'
import './Header.css'
import THError2 from '../../../Images/error2.png'

export default function Header(props) {
  const { saveSetting } = useContext(StoreContext)
  const logout = () => {
    Swal.fire({
      title: 'ออกจากระบบ?',
      text: 'คุณต้องการออกจากระบบ Referral System ใช่หรือไม่?',
      imageUrl: THError2,
      allowOutsideClick: false,
      showCancelButton: true,
      cancelButtonColor: '#c82333',
      confirmButtonText: 'ยืนยัน',
      cancelButtonText: 'ยกเลิก',
      reverseButtons: true
    }).then(result => {
      if (result.value === true) {
        sessionStorage.clear('staffJsonWebToken')
        sessionStorage.clear('staffSessionExpired')
        sessionStorage.clear('staffName')
        saveSetting({ case_id: 0,
          ajaxTimeOut : 60000,
          intervalTimeOut : 60000,
          recaptchaSiteKey : "6Lf3VAAVAAAAAPmVKVrsxRXzxbuh9ZL94LrsS2uv"
        })
        props.handleLogout()
      }
    })
  }

  const member = sessionStorage.getItem('staffName') ?? ''
  const staffButton = allowedUsage(sessionStorage.getItem('staffSessionExpired')) ?
    (<Row><Col>
      <Menu src={User} alt='Staff' width='48' index={1} active={true} onclick={logout} force={true} /></Col><Col><label style={{ fontWeight: 300, fontSize: 14, width: 'max-content' }}>{member}</label></Col></Row>
    ) : (<div />)


  return (
    <div>
      <nav className="main-header navbar navbar-expand-md navbar-light navbar-white">
        <div className="container">
          <span className="navbar-brand">
            <img src="/images/logo_thonburi_full.svg" alt="Thonburi Hospital" className="brand-image p-1" style={{ opacity: '.8', borderRight: 'solid 2px #0b78b6' }} />
            <span className="brand-text font-weight-light text-uppercase">Referral System</span>
          </span>

          { props.isCustomer ?  "" : staffButton }
        </div>
      </nav>
    </div>
  )
}
