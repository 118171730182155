import React from 'react'
import Content from '../../../../Components/Pages/TopNav/Content'
import StepProcessBar from '../../../../Components/StepProcessBar'
import FieldSet from '../../../../Components/FieldSet'

const Dashboard = ({match}) => {
    console.log(match)
  // Get all images menu
  const imageArr = importAll(require.context('../../../../Images/Menu', false, /\.(png)$/))
  function importAll(r) {
    return r.keys().map(r)
  }
  const initCaseOrder = parseInt(match.params.id) //start from 0
  const cases = initCaseOrder - 1
  // Get all menu
  const imageMenu = ['Information', 'Bed', 'Doctor', 'Ambulance', 'Success'].map((menu, index) => {
    return { id: index + 1, alt: menu, src: imageArr[index + (index <= cases ? 5 : 0)], active: index <= cases } 
  })
  const viewStatusReferCase = initCaseOrder
  const patientName = "Mr.Mark Otto"

  let getStatusReferCase = null


  getStatusReferCase = (
    <Content>
      <div className='main-screen'>
        <div className="container">
          {viewStatusReferCase === 1 ? (
            <div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลผู้รับบริการ">
                    <p className=" ml-5">ชื่อ-สกุล : {patientName}</p>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="สถานะการดำเนินการ">
                    <div className="row">
                      <div className="col text-center">
                        <StepProcessBar images={imageMenu} />
                        <p>โรงพยาบาลธนบุรี ยินดีให้บริการค่ะ</p>
                        <p>ขณะนี้กำลังสำรองเตียงให้ผู้บริการ</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
            </div>
          ) : null}
          {viewStatusReferCase === 2 ? (
            <div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลผู้รับบริการ">
                    <p className=" ml-5">ชื่อ-สกุล : {patientName}</p>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="สถานะการดำเนินการ">
                    <div className="row">
                      <div className="col text-center">
                        <StepProcessBar images={imageMenu} />
                        <p>สำรองเตียงสำหรับผู้รับบริการเรียบร้อยแล้วค่ะ</p>
                        <p>ขณะนี้กำลังดำเนินการประสานงานแพทย์</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลเตียงสำหรับผู้รับบริการ">
                    <div className="row">
                      <div className="col ml-5">
                        <p>หน่วยงาน : ICU</p>
                        <p>อาคาร : 4</p>
                        <p>ชั้นที่ : 7</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
            </div>
          ) : null}
          {viewStatusReferCase === 3 ? (
            <div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลผู้รับบริการ">
                    <p className=" ml-5">ชื่อ-สกุล : {patientName}</p>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="สถานะการดำเนินการ">
                    <div className="row">
                      <div className="col text-center">
                        <StepProcessBar images={imageMenu} />
                        <p>ดำเนินการประสานงานแพทย์เรียบร้อยแล้วค่ะ</p>
                        <p>ขณะนี้กำลังประสานงานเรื่องส่งตัวผู้รับบริการ</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
            </div>
          ) : null}
          {viewStatusReferCase === 4 ? (
            <div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลผู้รับบริการ">
                    <p className=" ml-5">ชื่อ-สกุล : {patientName}</p>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="สถานะการดำเนินการ">
                    <div className="row">
                      <div className="col text-center">
                        <StepProcessBar images={imageMenu} />
                        <p>ประสานงานเรื่องส่งตัวผู้ป่วยเรียบร้อยแล้วค่ะ</p>
                        <p>ขณะนี้กำลังอยู่ระหว่างการดำเนินการส่งตัวผู้รับบริการ</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลการส่งตัวผู้รับบริการ">
                    <div className="row">
                      <div className="col ml-5">
                        <p><strong>วิธีส่งตัวผู้รับบริการ</strong> รถพยาบาล รพ.ธนบุรีไปรับ</p>
                        <p><strong>วันที่คาดว่าจะมาถึง รพ.ต้นทาง</strong> 30/04/2563</p>
                        <p><strong>เวลาที่คาดว่าจะมาถึง รพ.ต้นทาง</strong> 08:00 น.</p>
                        <p><strong>วันที่คาดว่าจะถึง รพ.ธนบุรี</strong> 30/04/2563</p>
                        <p><strong>เวลาที่คาดว่าจะถึง รพ.ธนบุรี</strong> 10:00 น.</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
            </div>
          ) : null}
          {viewStatusReferCase === 5 ? (
            <div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลผู้รับบริการ">
                    <p className=" ml-5">ชื่อ-สกุล : {patientName}</p>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="สถานะการดำเนินการ">
                    <div className="row">
                      <div className="col text-center">
                        <StepProcessBar images={imageMenu} />
                        <p>ผู้รับบริการมาถึง รพ.ธนบุรีเรียบร้อยแล้วค่ะ</p>
                        <p>รพ.ธนบุรีขอบพระคุณเป็นอย่างสูงที่ได้รับความไว้วางใจจากท่าน</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <FieldSet title="ข้อมูลการส่งตัวผู้รับบริการ">
                    <div className="row">
                      <div className="col ml-5">
                        <p><strong>วันที่ผู้รับบริการมาถึง รพ.ธนบุรี</strong> 30/04/2563</p>
                        <p><strong>เวลาที่ผู้รับบริการมาถึง รพ.ธนบุรี</strong> 10.00 น.</p>
                      </div>
                    </div>
                  </FieldSet>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <br /><br /><br />
                  ระบุรูปภาพ
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Content>
  )

  return (
    <div>
      {getStatusReferCase}
    </div>
  )
}

export default Dashboard;
